import React from 'react'

export default function TrustPilotScore ({ stars, reviews, url, classNames, styles }) {
  if (stars === undefined) return <h3 className='margin-none'>-</h3>
  return (
    <a
      className={'trustpilot-widget-v2 trustpilot-widget-xs-center trustpilot-widget-small pull-right text-center ' + classNames}
      href={url}
      target="_blank"
      data-analytics
      data-analytics-category='fTrustpilotai'
      data-analytics-action='click'
      data-analytics-label='{company}'
      style={styles}
    >
      <span className='trust-logo'>
        <img
          src='/img/trustpilot/trustpilot_logo.svg'
          alt='Trustpilot'
          style={{ maxHeight: 11 }}
        />
      </span>
      <span className='stars' style={{ minHeight: 25 }}>
        <img
          alt={`${stars} Stars`}
          src={`/img/trustpilot/${stars}stars.svg`}
          style={{ margin: 6 }}
        />
      </span>
      <span style={{ fontSize: 12 }}>Based on {reviews} reviews</span>
    </a>
  )
}
