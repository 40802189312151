import React from 'react'
import classnames from 'classnames'

export default class Textarea extends React.Component {

  static get defaultProps () {
    return {
      disabled: false,
      error: null,
      onChange: () => {},
      required: false,
      uat: false, // User Acceptance Testing data attribute
      value: ''
    }
  }

  onChange = event => {
    return this.props.onChange(event.target.value)
  }

  renderError () {
    if (!this.props.error) return null
    return (
      <small className="help-block">
        {this.props.error}
      </small>
    )
  }

  renderLabel () {
    if (!this.props.label) return null
    return (
      <label
        htmlFor={this.props.name}
        className="control-label"
      >
        {this.props.label}
      </label>
    )
  }

  render () {
    return (
      <div className={classnames('form-group', {
        'is-required': this.props.required,
        'has-error': this.props.error !== null
      })}>
        {this.renderLabel()}
        <textarea
          type="text"
          className='form-control'
          disabled={this.props.disabled}
          id={this.props.name}
          name={this.props.name}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.onChange}
          style={{ height: '180px' }}
          {...(this.props.uat ? {'data-uat': this.props.uat} : {})}
        />
        {this.renderError()}
      </div>
    )
  }
}
