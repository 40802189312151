import React from 'react'
import classnames from 'classnames'
import addClass from '../../shared/addClass'
import removeClass from '../../shared/removeClass'

export default class Modal extends React.Component {
  static defaultProps = {
    displayHeader: true,
  }

  componentWillReceiveProps (nextProps) {
    const { isOpen } = this.props
    if (nextProps.isOpen && !isOpen) {
      addClass('body', 'modal-open')
    } else if (!nextProps.isOpen && isOpen) {
      removeClass('body', 'modal-open')
    }
  }

  componentDidUpdate () {
    const { focusableChildren } = this.props

    if (focusableChildren?.length > 0) {
      const tabHandler = evt => {
        if (evt.key === 'Tab') {
          evt.preventDefault()
          const currentElementIndex = focusableChildren.indexOf(evt.currentTarget)
          const nextFocusableElement = focusableChildren[currentElementIndex + 1] ?? focusableChildren[0]
          const prevFocusableElement =
            focusableChildren[currentElementIndex - 1] ?? focusableChildren[focusableChildren.length - 1]

          evt.shiftKey ? prevFocusableElement.focus() : nextFocusableElement.focus()
        }
      }

      focusableChildren.forEach(focusableElement => {
        focusableElement.removeEventListener('keydown', tabHandler)
        focusableElement.addEventListener('keydown', tabHandler)
      })
    }
  }

  componentDidMount () {
    const { focusableChildren } = this.props
    if (focusableChildren?.length > 0) {
      focusableChildren[0].focus()
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('keyup', this.keyUpHandler)
    }
  }

  componentWillUnmount () {
    if (this.props.isOpen) {
      removeClass('body', 'modal-open')
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('keyup', this.keyUpHandler)
    }
  }

  close = evt => {
    evt && evt.preventDefault()
    this.props.onRequestClose(evt)
  }

  keyUpHandler = evt => {
    if (evt.key === 'Escape') {
      this.close(evt)
    }
  }

  renderCloseButton () {
    return (
      <button type='button' className='close focus-ring' onClick={this.close}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33.41 33.41'
          style={{ height: '38px', width: '38px', padding: '8px', fill: 'currentColor' }}
        >
          <polygon points='33.41 1.41 32 0 16.71 15.29 1.41 0 0 1.41 15.29 16.71 0 32 1.41 33.41 16.71 18.12 32 33.41 33.41 32 18.12 16.71 33.41 1.41' />
        </svg>
      </button>
    )
  }

  renderHeader () {
    if (!this.props.displayHeader) return
    return (
      <div className='modal-header'>
        {this.renderCloseButton()}
        {this.props.title && <h3 className='modal-title' id={this.props.titleId}>{this.props.title}</h3>}
      </div>
    )
  }

  render () {
    const { className, isOpen, isFastTrackModal } = this.props
    if (!isOpen) return null
    return (
      <div>
        <div
          className={classnames('modal modal-centered fade', {
            in: isOpen,
          })}
          style={{ display: 'flex' }}
          onClick={this.close}
        >
          <div className={classnames('modal-dialog modal-dialog-wide', className)}>
            <div className='modal-content' onClick={evt => evt.stopPropagation()}>
              {isFastTrackModal ? (
                <>
                  <div className='modal-content__inner-container'>
                    {this.renderCloseButton()}
                    {this.props.title && <h2 className='modal-title' id={this.props.titleId}>{this.props.title}</h2>}
                    {this.props.headerContent}
                    {this.props.children}
                  </div>
                </>
              ) : (
                <>
                  {this.renderHeader()}
                  {this.props.children}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='modal-backdrop fade in' />
      </div>
    )
  }
}
