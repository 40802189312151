import React from 'react'
import classnames from 'classnames'
import addClass from '../../shared/addClass'
import removeClass from '../../shared/removeClass'

export default class Modal extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  static get defaultProps () {
    return {
      uat: false // User Acceptance Testing data attribute
    }
  }

  componentDidMount () {
    addClass('body', 'modal-open')
    setTimeout(() => {
      this.setState({
        visible: true
      })
    }, 10)
  }

  componentWillUnmount () {
    removeClass('body', 'modal-open')
  }

  close = (evt) => {
    evt && evt.preventDefault()
    this.props.onRequestClose()
  }

  renderHeader () {
    if (this.props.header === false) return null

    return (
      <div className="modal-header modal-header-dark">
        <a
          href="#"
          type="button"
          className="close"
          onClick={this.close}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.41 33.41" style={{ height: '38px', width: '38px', padding: '8px', fill: 'currentColor' }}><polygon points="33.41 1.41 32 0 16.71 15.29 1.41 0 0 1.41 15.29 16.71 0 32 1.41 33.41 16.71 18.12 32 33.41 33.41 32 18.12 16.71 33.41 1.41"/></svg>
        </a>
        <h3 className="modal-title text-center">{this.props.title}</h3>
      </div>
    )
  }

  render () {
    const { footer, containerClassName, className, uat, children, visible } = this.props
    if (!visible) return null

    return (
      <div>
        <div className={classnames('modal modal-centered fade', { 'in': this.state.visible }, (containerClassName))} style={{'display': 'block'}} onClick={(evt) => this.close(evt)}>
          <div
            className={classnames('modal-dialog', className)}
            onClick={(evt) => evt.stopPropagation()}
            {...(uat ? {'data-uat': uat} : {})}
          >
            <div className="modal-content">
              {this.renderHeader()}
              <div className={classnames('modal-body', { 'box': footer === false })}>
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade in" />
      </div>
    )
  }
}
