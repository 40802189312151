import React, { useContext } from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router'

import { LandingPageContext, ROUTER_TYPES } from './LandingPageContext'

// Determines the appropriate 'Link' component to use, depending on the router used by the app
const LandingPageLink = ({ cta }) => {
  const landingPageCtx = useContext(LandingPageContext)
  const classNames = 'a-button a-button--primary -without-icon landing-page__button'

  if (!cta?.href || !cta.text) {
    return null
  }

  const isLocal = cta.href && landingPageCtx?.routes?.includes(cta.href)

  if (!isLocal) {
    return (
      <a className={classNames} href={cta.href} onClick={cta.onClick}>
        {cta.text}
      </a>
    )
  }

  const LinkComponent = landingPageCtx?.routerType === ROUTER_TYPES.REACT_ROUTER ? ReactRouterLink : ReactRouterDomLink

  return (
    <LinkComponent className={classNames} to={cta.href} onClick={cta.onClick}>
      {cta.text}
    </LinkComponent>
  )
}
export default LandingPageLink
