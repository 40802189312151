export function trackPageView (page = window.location.pathname + window.location.search) {
  try {
    window.pushDataLayer({
      event: 'pageview',
      action: 'view',
      label: page,
    })
  } catch (err) {
    console.warn('Pushing event to GTM failed!')
  }
}

export function trackEvent (action, label) {
  try {
    window.pushDataLayer({
      event: 'express',
      action: action,
      label: label,
    })
  } catch (err) {
    console.warn('Pushing event to GTM failed!')
  }
}

export function trackFbEvent (label) {
  try {
    fbq('track', label)
  } catch (err) {
    console.warn('Sending event to FBQ failed!', label, err)
  }
}

export function trackSurveyEvent (action, label) {
  try {
    window.pushDataLayer({
      event: 'fast-track-surveys',
      action: action,
      label: label,
    })
  } catch (err) {
    console.warn('Pushing event to GTM failed!')
  }
}

export function trackConversion (label) {
  try {
    window.pushDataLayer({
      event: 'conversion',
      action: 'Opportunity Generated',
      label: label,
    })
  } catch (err) {
    console.warn('Pushing event to GTM failed!')
  }
}
