const STANDARD_23 = [4, 3, 6, 10]

export const PRODUCT_TYPES = [
  { name: 'Boiler', maxLength: STANDARD_23 },
  { name: 'Cylinder', maxLength: STANDARD_23 },
  { name: 'Bundle', maxLength: STANDARD_23 },
  { name: 'Wave Control', maxLength: 9, suffix: '7716192072' },
  { name: 'Easy Control', maxLength: 9, easyControl: true },
  { name: 'Control (Sense & Comfort)', maxLength: STANDARD_23 },
  { name: 'Other Controls', maxLength: 10 },
  { name: 'Filter', maxLength: STANDARD_23 },
  { name: 'Solar', maxLength: STANDARD_23 },
  { name: 'Pump Station', maxLength: 10 },
  { name: 'Heat Pump Indoor Unit', maxLength: STANDARD_23 },
  { name: 'Heat Pump Outdoor Unit', maxLength: STANDARD_23 },
  { name: 'Heat Pump Tower Unit', maxLength: STANDARD_23 },
  { name: 'Heat Pump Hybrid Unit', maxLength: STANDARD_23 },
  { name: 'Commercial Heating', maxLength: STANDARD_23 },
  { name: 'Stove', maxLength: STANDARD_23 },
  { name: 'Air Conditioning', maxLength: STANDARD_23 },
]

const PRODUCT_TYPES_FILTER = string => PRODUCT_TYPES.filter(({ name }) => name.includes(string)).map(({ name }) => name)
export const HYBRID_PRODUCTS = PRODUCT_TYPES_FILTER('Hybrid')
export const HEAT_PUMP_PRODUCTS = PRODUCT_TYPES_FILTER('Heat Pump')
export const AIR_CONDITIONING_PRODUCTS = PRODUCT_TYPES_FILTER('Air Conditioning')

export const EASY_CONTROL = 'Easy Control'

export const EASY_CONTROL_COLOURS = [
  { colour: 'White', suffix: '7736701341' },
  { colour: 'Black', suffix: '7736701392' },
]

export const MAX_SMART_TRVS = 20
export const SMART_TRV_SERIAL = '7736701575'
export const BUNDLE = 'Bundle'
export const ACCESSORY_BUNDLE = 'Bundle\\AccessoryBundle'

export const WAI = 'WAI'
export const SPEC_PORTAL = 'CPP'
export const CONSUMER = 'Consumer'
export const PROFESSIONAL = 'Professional'

export const PATHS = {
  [WAI]: {
    rootPath: '/guarantees',
    allGuarantees: '/guarantees/all',
    newGuarantee: '/support/guarantees',
  },
  [SPEC_PORTAL]: {
    rootPath: '/manage/guarantees',
    allGuarantees: '/manage/guarantees',
    newGuarantee: '/manage/guarantees/create',
  },
  [CONSUMER]: {
    rootPath: '/support/guarantees',
    newGuarantee: '/support/guarantees',
  },
  [PROFESSIONAL]: {
    rootPath: '/professional/support/guarantees',
    newGuarantee: '/professional/support/guarantees',
  },
}

export const GAS_SAFE_STATUS = {
  UNKNOWN: 'UNKNOWN',
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
}

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const BENCHMARK_OPTIONS = [
  { label: 'Pre-existing', value: 0 },
  { label: 'Fitted', value: 1 },
  { label: 'Not required', value: 2 },
]

export const GAS_RATE_UNIT_OPTIONS = [
  { label: 'm3/hr', value: 0 },
  { label: 'ft3/hr', value: 1 },
]

export const BRANDS_PRODUCTS_DROPDOWN_TYPES = {
  system_cleaner: {
    type: 'system_cleaner',
    brand: 'system_cleaners',
    product: 'system_cleaner_products',
  },
  water_scale_reducer: {
    type: 'water_scale_reducer',
    brand: 'water_scale_reducers',
    product: 'water_scale_reducer_products',
  },
  inhibitor: {
    type: 'inhibitor',
    brand: 'inhibitors',
    product: 'inhibitor_products',
  },
  primary_water_filter: {
    type: 'primary_water_filter',
    brand: 'primary_water_filters',
    product: 'primary_water_filter_products',
  },
}

export const CONTENT_KEYS = {
  INITIAL: 'initial',
  COMBI: 'combi',
  NON_COMBI: 'nonCombi',
}

export const extensions = ['jpg', 'jpeg', 'png']
export const INSTALLER_SIGNATURE = 'installer_signature'
export const CUSTOMER_SIGNATURE = 'customer_signature'

export const FORM_DEFAULT_VALUES = [
  { property: 'water_scale_reducer', value: 2 },
  { property: 'output_left_at_factory_settings', value: false },
  { property: 'is_installation_in_a_hard_water_area', value: false },
  { property: 'water_meter_fitted', value: false },
]
