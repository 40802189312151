import api from '../api'
import prepareSalesLead from '../presenters/SalesLeadPresenter'
import uuidv4 from 'uuid/v4'

/**
 * Submit a Sales Lead
 */
export default (enquiry, installers, companies) => {
  // prep/get ab tests from browser window
  let abData = window._vwo_exp
  let abTests = []
  if (abData !== undefined) {
    abTests = Object.keys(abData).map((prop) => {
      if (abData.hasOwnProperty(prop)) {
        return [{
          id: uuidv4(),
          name: abData[prop].name,
          variant: abData[prop].comb_n[abData[prop].combination_chosen]
        }]
      }
    })
  }

  const salesLeadData = {
    'id': enquiry.id || uuidv4(),
    'priority': enquiry.priority,
    'description': enquiry.message,
    'bestTimeToCall': 'Anytime',
    'title': enquiry.title,
    'firstName': enquiry.firstName,
    'lastName': enquiry.lastName,
    'emailAddress': enquiry.emailAddress,
    'telephoneNumber': enquiry.telephone,
    'allowReallocation': enquiry.allowReallocation,
    'finance': enquiry.finance,
    installers,
    companies,
    'ab-tests': abTests,
    'serviceType': enquiry.type,
    address: {
      'id': enquiry.id || uuidv4(),
      'unitNumber': enquiry.unitNumber,
      'houseNumber': enquiry.houseNumber,
      'buildingName': enquiry.buildingName,
      'street': enquiry.street,
      'district': enquiry.district,
      'city': enquiry.city,
      'country': enquiry.country,
      'postcode': enquiry.postcode,
      'pcaId': enquiry.pcaId,
      'longitude': enquiry.longitude,
      'latitude': enquiry.latitude
    },
    'installation-enquiry': {
      'id': enquiry.id,
      'offerAgreed': enquiry.offerAgreed,
      'userType': enquiry.userType
    }
  }

  return api.post(
    '/sales-leads',
    prepareSalesLead(salesLeadData)
  )
}
