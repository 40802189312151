const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const nth = day => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1: return 'st'
    case 2: return 'nd'
    case 3: return 'rd'
    default: return 'th'
  }
}

/**
 * Formats a date as 'October 2018'
 * Accepts either a Date or a string in a valid date format
 * @param {String|Date} date The date to format
 * @return The formatted date
 */
export const formatMonth = date => {
  if (!date) return date
  if (typeof date === 'string') date = new Date(date)
  if (!(date instanceof Date) || isNaN(date)) return '-'
  const month = MONTHS[date.getMonth()]
  const year = date.getFullYear()
  return `${month} ${year}`
}

/**
 * Formats a date as 'Wednesday 4th October 2018'
 * Accepts either a Date or a string in a valid date format
 * @param {String|Date} date The date to format
 * @return The formatted date
 */
export default date => {
  if (!date) return date
  if (typeof date === 'string') date = new Date(date)
  if (!(date instanceof Date) || isNaN(date)) return '-'
  const day = DAYS[date.getDay()]
  const ordinal = nth(date.getDate())
  const month = MONTHS[date.getMonth()]

  return `${day} ${date.getDate()}${ordinal} ${month} ${date.getFullYear()}`
}
