import React, { useState } from 'react'
import logBenchmarkOnboarding from '../../network/requests/logBenchmarkOnboarding'
import getUserBenchmark from '../../network/requests/getUserBenchmark'
import { CONTENT_STATE } from './index'

const ConfirmCreation = props => {
  const { setNextContent, closeModal, onConnect } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleConfirm = () => {
    if (loading) return
    setError('')
    setLoading(true)

    getUserBenchmark()
      .then(benchmark => {
        if (!benchmark) {
          setError('Your accounts have not been connected, please try again')
        } else {
          setNextContent(CONTENT_STATE.ACCOUNTS_CONNECTED)
          onConnect()
        }
      })
      .catch(() => {
        setError('An error has occured, please try again')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='onboarding__content--installer'>
      <h3 className='onboarding__title--installer -size-xl m-t-0'>Confirm your Benchmark account creation and connection</h3>
      <p className='onboarding__text--installer'>
        To complete connection of your accounts, ensure you have accepted the terms in the tab just opened. Then return
        here to confirm the terms have been accepted by pressing the button labelled I have accepted terms, please connect accounts
      </p>
      <div className='onboarding__buttons'>
        <div className="a-link a-link--button-secondary onboarding__button--connect">
          <a
            className='onboarding__button--connect'
            style={{ border: 'none' }}
            onClick={() => {
              logBenchmarkOnboarding()
              closeModal()
            }}>
            <span>Connect later</span>
          </a>
        </div>
        <br/>
        <div className="a-link a-link--button onboarding__button--connect">
          <a className='onboarding__button--connect' onClick={handleConfirm}>
            <span>I have accepted terms, please connect accounts</span>
          </a>
        </div>
      </div>
      <div className='onboarding__error'>{error}</div>
    </div>
  )
}

export default ConfirmCreation
