import React from 'react'

const InputText = props => {
  const { error, onChange, value, label, placeholder = '', type = 'text' } = props

  return (
    <div className="m-form-field">
      <div className="a-text-field">
        <label className='input__label details__header onboarding__label'>{label}</label>
        <input placeholder={placeholder} className='input__control onboarding__input-control' type={type} value={value} onChange={onChange}></input>
      </div>
      {error &&
      (<div className="a-notification a-notification--text -error" role="alert">
        <i className="a-icon ui-ic-alert-error"></i>
        <div id="text-field-5-label" className="a-notification__content">
          {error}
        </div>
      </div>)
      }
    </div>
  )
}

export default InputText
