import React from 'react' // eslint-disable-line no-unused-vars
import { MODAL_GUIDE_CONTENTS, MODAL_GUIDE_TITLE } from './components/PhotoGuideModal'

export const QUESTIONS = [
  {
    number: 1,
    name: 'combi_boiler',
    icon: 'system',
    iconAlt: 'A combi boiler',
    title: 'Do you have a wall-hung combi boiler?',
    helpText: 'Tell me more about the different types of boilers',
    analyticsTag: 'Combi',
    answers: [
      { icon: 'close', value: false, nextQuestion: 2 },
      { icon: 'check', value: true, nextQuestion: 2 },
    ],
  },
  {
    number: 2,
    name: 'fuel_gas',
    icon: 'fire',
    iconAlt: 'Gas fueled boiler',
    title: `Is your boiler's fuel type natural gas?`,
    helpText: 'Tell me more about fuel types',
    analyticsTag: 'Gas',
    answers: [
      { icon: 'close', value: false, nextQuestion: 3 },
      { icon: 'check', value: 'Gas', nextQuestion: 4 },
    ],
  },
  {
    number: 3,
    name: 'fuel_gas',
    icon: 'fire',
    iconAlt: 'Boiler fuel type',
    title: 'What fuel type is your boiler?',
    helpText: 'Tell me more about fuel types',
    analyticsTag: 'FuelType',
    answers: [
      { icon: 'oil', value: 'Oil', nextQuestion: 4, label: 'Oil' },
      { icon: 'lpg', value: 'LPG', nextQuestion: 4, label: 'LPG' },
    ],
  },
  {
    number: 4,
    name: 'boiler_same_location',
    icon: 'floor-plan',
    iconAlt: '',
    title: 'Will your new boiler be in the same place?',
    helpText: 'Tell me more about boiler relocation',
    analyticsTag: 'Location',
    answers: [
      { icon: 'close', value: false },
      { icon: 'check', value: true },
    ],
  },
]

export const INCLUDED_ITEMS = [
  {
    title: 'Greenstar Comfort control',
    text: 'Your choice of Worcester Bosch combi boiler',
    imageUrl: '/img/products/boiler-controls/boilers-image.png',
    imageAlt: 'Comfort I Boiler Controller',
  },
  {
    title: 'System cleanse',
    text: 'A wireless or smart control',
    imageUrl: '/img/products/boiler-controls/controls-image.png',
    imageAlt: 'System cleanse products',
  },
  {
    title: 'Greenstar System Filter',
    text: 'A system flush, cleanse and filter, to maximise efficiency',
    imageUrl: '/img/products/boiler-controls/filter-image.png',
    imageAlt: 'Greenstar System Filter',
  },
  {
    title: 'Installation by an Accredited Installer',
    text: 'Installation by an Accredited Installer with up to a 12 year guarantee',
    imageUrl: '/img/products/boiler-controls/installer-image.png',
    imageAlt: 'Worcester Bosch Accredited installer',
  },
]

export const HOW_DOES_IT_WORK = [
  {
    title: 'Select your new boiler',
    text1: 'Answer some simple questions about your boiler, send us photos of your installation & choose a new boiler.',
    imageUrl: 'img/fast-track/how-it-works/boiler-grad.svg',
    imageAlt: 'Select your boiler',
    number: 1,
  },
  {
    title: 'Remote survey & quote',
    text1: "We'll do a remote survey for you and provide a maximum price quote from an Accredited Installer",
    imageUrl: 'img/fast-track/how-it-works/details-grad.svg',
    imageAlt: 'Enter Details',
    number: 2,
  },
  {
    title: 'Installation arranged',
    text1:
      'We arrange installation on a suitable date – completely free of charge. You pay the installer the quoted price.',
    text2: 'You will then pay the WAI on completion of the work.',
    imageUrl: 'img/fast-track/how-it-works/install-grad.svg',
    imageAlt: 'Boiler Install',
    number: 3,
  },
]

export const MODAL_CONTENTS = [
  {
    number: 1,
    title: 'Do you have a combi boiler?',
    children: (
      <div>
        <div className='text-center fast-track-modal-container'>
          <div className='video embed-responsive'>
            <iframe className='embed-iframe' title='video embed' />
            <div
              className='youtube'
              data-youtube-id='Kt-mS4PNxMo'
              style={{
                backgroundColor: '#000',
                backgroundImage: 'url(https://img.youtube.com/vi/Kt-mS4PNxMo/0.jpg)',
                left: '0',
                backgroundPosition: 'center center',
              }}
            >
              <div className='play' />
            </div>
          </div>
        </div>
        <p>
          A combination or {"'combi'"} boiler is both a high efficiency water heater and a central heating boiler in a
          single compact unit.
        </p>
        <p>
          If your heating system contains a cylinder or water storage tanks in the loft you most likely have either a
          System or Regular boiler.
        </p>
        <p>
          If you currently have a system or regular boiler, or are simply unsure which type of boiler you have, we
          recommend speaking to one of your{' '}
          {
            <a href='https://www.worcester-bosch.co.uk/products/boilers/find-an-installer'>
              local Worcester Accredited Installers.
            </a>
          }
        </p>
      </div>
    ),
  },
  {
    number: 2,
    title: 'Which type of fuel do I have?',
    children: (
      <div>
        <p>
          At present our FastTrack service is only available for direct replacement of an existing natural gas combi
          boiler.
        </p>
        <p>
          Gas boilers are powered by natural gas bought directly to your property via a gas main. Many properties will
          use gas powered boilers, especially in built-up areas. One of the easiest ways to check is if you have a gas
          meter and receive bills from a gas utility provider.
        </p>
        <p>Other types of boiler include oil and LPG.</p>
        <p>
          Oil boilers will have a storage tank somewhere outside of the property which is filled with oil. This is
          delivered to you by a tanker truck.
        </p>
        <div className='text-center margin-bottom-modal'>
          <img src='/img/fast-track/how-it-works/fuel-type.png' alt='What is flue' />
        </div>
        <p>
          If you are still unsure which type of fuel you have, we recommend speaking to one of your{' '}
          {
            <a href='https://www.worcester-bosch.co.uk/products/boilers/find-an-installer'>
              local Worcester Accredited Installers
            </a>
          }{' '}
          for advice.
        </p>
      </div>
    ),
  },
  {
    number: 3,
    title: 'Which type of fuel do I have?',
    children: (
      <div>
        <p>
          At present our FastTrack service is only available for direct replacement of an existing natural gas combi
          boiler.
        </p>
        <p>
          Gas boilers are powered by natural gas bought directly to your property via a gas main. Many properties will
          use gas powered boilers, especially in built-up areas. One of the easiest ways to check is if you have a gas
          meter and receive bills from a gas utility provider.
        </p>
        <p>Other types of boiler include oil and LPG.</p>
        <p>
          Oil boilers will have a storage tank somewhere outside of the property which is filled with oil. This is
          delivered to you by a tanker truck.
        </p>
        <div className='text-center margin-bottom-modal'>
          <img src='/img/fast-track/how-it-works/fuel-type.png' alt='What is flue' />
        </div>
        <p>
          If you are still unsure which type of fuel you have, we recommend speaking to one of your{' '}
          {
            <a href='https://www.worcester-bosch.co.uk/products/boilers/find-an-installer'>
              local Worcester Accredited Installers
            </a>
          }{' '}
          for advice.
        </p>
      </div>
    ),
  },
  {
    number: 4,
    title: 'Can my boiler be moved to a different place?',
    children: (
      <div>
        <p>
          Unfortunately not through our FastTrack service. Replacement of your old gas combi boiler with a new gas combi
          boiler must be in the same location.
        </p>
        <p>
          Relocating a boiler will require additional work to move pipework and prepare the area and incur extra
          complexity and cost. If you require your new boiler in a different location please{' '}
          <a href='https://www.worcester-bosch.co.uk/products/boilers/find-an-installer'>
            contact one of your local Worcester Accredited Installers
          </a>{' '}
          for a full assessment and quote.
        </p>
      </div>
    ),
  },
  {
    title: MODAL_GUIDE_TITLE,
    children: MODAL_GUIDE_CONTENTS,
  },
]

export const NOT_ELIGIBLE_AREA = 'Unfortunately, our FastTrack service is not currently available in your area'
export const NOT_ELIGIBLE_REQUIREMENTS =
  'Sorry, we are unable to provide the FastTrack service for you, but we can still help..'
export const NOT_ELIGIBLE_REQUIREMENTS_TEXTS =
  'Your answer means your current installation does not fit the criteria for the FastTrack service. However, if your installation is urgent we would advise speaking directly to one of our Worcester Accredited Installers as they will be able to arrange a survey. They will need to visit your property to assess the work required and provide you with an installation date and quote. Please be aware this may not be within 72 hours for some installers.'
export const NOT_ELIGIBLE_AREA_TEXT = (
  <span>
    It looks like we have no available installation dates within your area. If your installation is urgent we advise
    speaking directly to one of our Worcester Accredited Installers through our{' '}
    <a href='https://www.worcester-bosch.co.uk/how-to-buy' target='_blank' rel='noopener noreferrer'>
      {' '}
      Find an installer tool{' '}
    </a>
    . Alternatively, please try again in the next few days to check for any new availability.
  </span>
)
export const FIND_INSTALLER = 'Find a local installer'
export const CONTACT_INSTALLER = 'Contact a local Worcester Accredited Installer'
export const ERROR = 'Something went wrong!'
export const STATUS_INSTALLATION_COMPLETE = 'installation_complete'
export const STATUS_JOB_WON = 'job_won'
