import Presenter from '../../../shared/network/presenters/Presenter'
import { AddressPresenter } from '../../../guarantee/network/presenters/AddressPresenter'
import { PackagePresenter } from './PackagePresenter'
import { CustomerPhotoPresenter } from './CustomerPhotoPresenter'
import { InstallerEvidencePresenter } from './InstallerEvidencePresenter'
import { ResponsePresenter } from './ResponsePresenter'
import { PromotionPresenter } from './PromotionPresenter'

export class FastTrackJobPresenter extends Presenter {
  relationships () {
    return {
      'installation-address': AddressPresenter,
      'package': PackagePresenter,
      'customer-photos': CustomerPhotoPresenter,
      'installer-evidence': InstallerEvidencePresenter,
      responses: ResponsePresenter,
      promotion: PromotionPresenter,
    }
  }
}

FastTrackJobPresenter.prototype.type = 'fast-track-order'

// Fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(FastTrackJobPresenter, Presenter)

export default data => FastTrackJobPresenter.render(data)
