import React from 'react'
import ReactDOM from 'react-dom'

export default function mountReact (Component, selectorOrElement) {
  const mountNode = $(selectorOrElement)
  mountNode.each(function () {
    let props = $(this).data()
    ReactDOM.render(
      <Component {...props} />, this)
  })
}


// import React from 'react';
// import ReactDOM from 'react-dom';

// export default function mountReact(Component, selectorOrElement) {
//   const mountNodes = document.querySelectorAll(selectorOrElement);
//   mountNodes.forEach((mountNode) => {
//     const props = Object.assign({}, mountNode.dataset);
//     ReactDOM.createRoot(mountNode).render(<Component {...props} />);
//   });
// }
