import React, { useState } from 'react'
import uploadFileRequest from '../../../../shared/network/requests/uploadFileRequest'
import ImageUpload from '../../../../fast-track/screens/PhotoUploadScreen/components/ImageUpload'

function Image ({ name, onChange, value, editable, error }) {
  // TODO loading overlay for file uploads
  // also need to pass up to parent form to disable the submit button
  // const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(value)

  const handleChange = (file) => {
    // setLoading(true)
    setUrl(URL.createObjectURL(file))
    uploadFileRequest(file).then((response) => {
      // setLoading(false)
      onChange(name, Number(response.id))
    })
  }

  const handleError = (error) => console.error(error)

  return (
    <span>
      <img className="previewImage" src={url} />
      {editable && (
        <ImageUpload
          error={error}
          onChange={image => handleChange(image)}
          onError={error => handleError(error)}
        />
      )}
    </span>
  )
}

export default Image
