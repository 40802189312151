import React from 'react'
import MediaQuery from 'react-responsive'
import Constants from '../../shared/constants'

export default class PinnedButton extends React.Component {

  static get defaultProps () {
    return {
      renderOnMobile: true,
      uat: false // User Acceptance Testing data attribute
    }
  }

  onClick = (event) => {
    event.preventDefault()
    this.props.onClick()
  }

  render () {
    if (this.props.disabled) return null
    var minWidthToRender = this.props.renderOnMobile ? 0 : Constants.BREAKPOINT_MD_MIN
    return (
      <MediaQuery minWidth={minWidthToRender}>
        <a
          href="#"
          className="pinned-button"
          onClick={this.onClick}
          {...(this.props.uat ? {'data-uat': this.props.uat} : {})}
        >{this.props.text}</a>
      </MediaQuery>
    )
  }
}
