import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FormGroup from './FormGroup'

export default class Checkbox extends React.Component {

  static propTypes = {
    error: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    style: PropTypes.object,
    checked: PropTypes.bool
  }

  static defaultProps = {
    required: false,
    checked: false,
    inline: true,
    style: {}
  }

  handleOnChange = event => {
    const { onChange } = this.props
    onChange && onChange(event.target.checked)
  }

  render () {
    const { name, text, checked, inline, style } = this.props
    const id = `cbx_${name}`
    return (
      <FormGroup {...this.props} >
        <div className={classnames('checkbox', { 'checkbox-inline': inline })} style={style}>
          <label htmlFor={id} className={'control-label'}>
            <input
              id={id}
              name={name}
              type='checkbox'
              checked={checked}
              onChange={this.handleOnChange}
            />
            {text}
          </label>
        </div>
      </FormGroup>
    )
  }
}
