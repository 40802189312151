import React from 'react'

function FrokText ({ name, onChange, value, editable, error, disabled, placeholder, label, size, className, required, displayLabel, displayLabelAsTitle }) {
  if (!editable) return value
  return (
    <div className={`m-form-field ${size ? `m-form-field--${size}` : ''} ${className}`}>
      {displayLabelAsTitle && (
        <h3 className="-size-xl -strong">
          {label}{required && '*'}
        </h3>
      )}
      <div className="a-text-field">
        {!displayLabelAsTitle && (<label htmlFor={name}>{label}</label>)}
        <input
          type="text"
          onChange={e => onChange(name, e.target.value)}
          id={name}
          value={value === null ? '' : value}
          disabled={disabled}
          placeholder={placeholder === null ? '' : placeholder} />
      </div>
      {error ? (
        <div className="a-notification a-notification--text -error" role="alert">
            <div className="a-notification__content">{error}</div>
        </div>
      ) : (
            ''
      )}
    </div>
  )
}

export default FrokText
