import React from 'react'
import cx from 'classnames'

function Text ({ name, onChange, value, editable, error }) {
  if (!editable) return <a href={value}>{value}</a>
  return (
    <div className={cx(error && 'has-error', 'input__container--text')}>
      <input
        className={'input__control'}
        onChange={e => onChange(name, e.target.value)}
        value={value === null ? '' : value}
        type="text"
      />
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default Text
