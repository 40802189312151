import Presenter from './Presenter'

export class AddressPresenter extends Presenter {}

AddressPresenter.prototype.type = 'address'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(AddressPresenter, Presenter)

export default data => AddressPresenter.render(data)
