/* eslint no-template-curly-in-string: 0 */

import { string, object } from 'yup'

const required = '${label} is required'

export default object().shape({
  unitNumber: string().nullable(),
  buildingName: string().nullable(),
  houseNumber: string().nullable(),
  country: string(required).nullable().required().label('Country'),
  street: string(required).nullable().required().label('Street'),
  city: string(required).nullable().required().label('City'),
  postcode: string(required).nullable().required().label('Postcode'),
}).test(
  'unitHouseBuilding',
  'At least one of Unit Number, Building Name or House Number is required',
  function ({ unitNumber, houseNumber, buildingName }) {
    const path = `unitHouseBuilding`
    if ([unitNumber, houseNumber, buildingName].every(isEmpty)) return this.createError({ path })
    return true
  }
)

const isEmpty = value => !value
