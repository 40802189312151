import React from 'react'
import Modal from '../../lifestyle/components/Modal'

export const MODAL_GUIDE_TITLE = 'A guide to taking photos of your current installation'

export const MODAL_GUIDE_CONTENTS = (
  <div className="fast-track-photo-guide">
    <div className='grid grid-two-col grid-col-left'>
      <div className='grid-col'>
        <p>
          We need a photo of your boiler to help us assess if it meets current installation regulations. If the flue will
          need relocating there may be an additional charge. A member of our team will discuss this with you prior to
          installation.
        </p>
        <h4>Please provide four images:</h4>
        <ol className="fast-track-photo-guide-list">
          <li>From inside, showing your flue and current boiler</li>
          <li>Close-up of the pipework coming out of the bottom of the boiler</li>
          <li>From the outside showing where the flue exists in the building.</li>
          <li>A head on shot of your gas metre showing the pipework.</li>
        </ol>
        <p>
          If your flue exits somewhere that is not easily seen from the ground never endanger yourself to attempt to
          take a photo.
        </p>
        <p>
          Our team will discuss your flue with you, and the installer will evaluate the situation upon
          arrival.
        </p>
      </div>

      <div className="grid-col">
        <div className="grid grid-two-col">
          <div className="grid-col fast-track-photo-guide-img-info">
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue.png' />
              <i className="icon icon-close text-bubble text-bubble-danger"></i>
            </div>
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue-2.png' />
              <i className="icon icon-check text-bubble"></i>
            </div>
            <p className="margin-top text-center">
              Try to take a head-on shot rather than from the side if possible and
              ensure the flue exiting the boiler can be seen.
            </p>
          </div>
          <div className="grid-col fast-track-photo-guide-img-info">
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue-3.png' />
              <i className="icon icon-close text-bubble text-bubble-danger"></i>
            </div>
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue-4.png' />
              <i className="icon icon-check text-bubble"></i>
            </div>
            <p className="margin-top text-center">Include as much of the pipework as possible coming out of the bottom of the boiler.</p>
          </div>
        </div>
        <div className="grid grid-two-col margin-top">
          <div className="grid-col fast-track-photo-guide-img-info">
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue-5.png' />
              <i className="icon icon-close text-bubble text-bubble-danger"></i>
            </div>
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/flue-6.png' />
              <i className="icon icon-check text-bubble"></i>
            </div>
            <p className="margin-top text-center">
              Avoid taking a close-up. If a window or door is nearby try to keep this in shot and if your flue comes
              out of a downstairs wall, make sure the ground is included within the photo.
            </p>
          </div>
          <div className="grid-col fast-track-photo-guide-img-info">
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/gm-4.png' />
              <i className="icon icon-close text-bubble text-bubble-danger"></i>
            </div>
            <div className="img-info-wrap">
              <img src='/img/fast-track/package-includes/GM3.png' />
              <i className="icon icon-check text-bubble"></i>
            </div>
            <p className="margin-top text-center">
              Try to take a full shot of the gas meter, showing as much of the pipework going in and out of it as possible.
              It doesn’t matter if we aren’t able to read the numbers.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

function PhotoGuideModal (props) {
  return (
    <Modal
      {...props}
      title={MODAL_GUIDE_TITLE}
      className='modal-lg'
    >
      <div
        className={'modal-body'}
        data-test-id='modal-body'
      >
        {MODAL_GUIDE_CONTENTS}
      </div>
    </Modal>
  )
}

export default PhotoGuideModal
