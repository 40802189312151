import React from 'react'

import LandingPageLink from './LandingPageLink'
import FaiAddressSearch from '../../../fai-address-search/components/FaiAddressSearch'

const HeroBanner = ({
  image,
  mobileImage,
  heading,
  subHeading,
  uniqueSellingPoints,
  gradientOverlay,
  cta,
  faiAddressSearch,
  faiAddressSubmitFunction,
  enableFaiTracking,
  whiteBackground,
}) => {
  return (
    <section className='landing-page-hero-banner m-banner default-section-spacing'>
      <div className='m-banner__background landing-page-hero-banner__background'>
        {image && (
          <picture
            className={`landing-page-hero-banner__background-image-container${
              gradientOverlay ? ' landing-page-hero-banner__background-image-container--gradient-overlay' : ''
            }`}
          >
            <source srcSet={image} media='(min-width: 768px)' />
            <img src={mobileImage || image} role='presentation' className='landing-page-hero-banner__background-image' />
          </picture>
        )}

        <div className='e-container'>
          <div className='landing-page-hero-banner__wrapper'>
            <div className={whiteBackground && 'landing-page-hero-banner__wrapper--white-background'}>
              {heading && <h1 className='landing-page-hero-banner__heading -size-4xl'>{heading}</h1>}

              {subHeading && <p className='landing-page-hero-banner__subHeading'>{subHeading}</p>}

              {faiAddressSearch && (
                <FaiAddressSearch enableFaiTracking={enableFaiTracking} submitFunction={faiAddressSubmitFunction} />
              )}

              <ul className='landing-page-hero-banner__list'>
                {uniqueSellingPoints &&
                  uniqueSellingPoints.length > 0 &&
                  uniqueSellingPoints.map((point, index) => {
                    return (
                      <li key={index} className='landing-page-hero-banner__list-item'>
                        {point.icon
                          ? <img className="landing-page-hero-banner__list-item-icon" src={`/img/${point.icon}`} alt={point.text} />
                          : <i className='a-icon ui-ic-alert-success'></i>
                        }
                        <span>{point.text}</span>
                      </li>
                    )
                  })}
              </ul>

              {cta?.href && cta.text && <LandingPageLink cta={cta}/>}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroBanner
