import basketItemByProductNumberSelector from './basketItemByProductNumberSelector'

export default state => {
  const { all } = state.basket
  return all
    .map(productNumber => basketItemByProductNumberSelector(state, productNumber))
    .reduce(totalPriceWithoutVat, 0)
}

export const totalPriceWithoutVat = (total, product) => {
  const { priceWithoutVat = 0, quantity = 1 } = product
  return total + (priceWithoutVat * quantity)
}
