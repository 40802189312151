import React from 'react'
import CKEditor from 'ckeditor4-react'
import cx from 'classnames'

const Wysiwyg = ({ name, value, onChange, editable, error }) => {
  const config = {
    contentsCss: ['/css/worcester-bosch.css?v=1', 'body { background: white; padding: 16px; }'],
    format_tags: 'p;h3;h4;h5;h6',
    filebrowserBrowseUrl: '/filemanager/dialog.php',
    uiColor: '#eeeeee',
    toolbar: [
      [
        'Format'
      ],
      [
        'Bold',
        'Italic',
        'Underline'
      ],
      [
        'Blockquote',
        'NumberedList',
        'BulletedList'
      ]
    ],
    stylesSet: 'worcester_bosch',
  }
  if (!editable) {
    return <div dangerouslySetInnerHTML={{ __html: value }} />
  }
  return (
    <div className={cx(error && 'has-error', 'input__container--text')}>
      <CKEditor
        data={value}
        config={config}
        onInit={event => onChange(name, event.editor.getData())}
        onChange={event => onChange(name, event.editor.getData())}
      />
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default Wysiwyg
