import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from './FormGroup'
import { DESELECT } from '../constants'

export default class Select extends React.Component {

  static DESELECT = '__DESELECT'

  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    isFastTrackForm: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    placeholder: 'Please select',
    required: false,
    value: undefined,
    isFastTrackForm: false,
  }

  handleOnChange = event => {
    const { onChange, name } = this.props
    const { value } = event.target
    onChange && onChange(value === DESELECT ? undefined : value, name)
  }

  renderOptions () {
    const { options, placeholder } = this.props
    const elements = options.map(({ value, label }, key) => (
      <option
        key={key}
        value={value}
      >{label}</option>
    ))
    elements.unshift(<option key={'_'} value={DESELECT}>{placeholder}</option>)
    return elements
  }


  renderSelect () {
    const { value, disabled, name } = this.props
    return (
      <select
        className='form-control'
        id={name}
        name={name}
        onChange={this.handleOnChange}
        disabled={disabled}
        value={value || undefined}
      >
        {this.renderOptions()}
      </select>
    )
  }

  render () {
    return (
      <FormGroup {...this.props}>
        {this.props.isFastTrackForm ? (
          <div className='form-control__select-wrapper'>
            {this.renderSelect()}
            <span className='icon boschicon-bosch-ic-down' />
          </div>
        ) : (
          this.renderSelect()
        )}
      </FormGroup>
    )
  }
}
