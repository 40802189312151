import React from 'react'
import FilePicker from '../../../components/FilePicker'
import classnames from 'classnames'

export default function ImageUpload (props) {
  const { label, extensions, note, image, onError, onChange, error } = props
  const containerClasses = classnames('form-group', {
    'has-error': error !== undefined,
  })
  return (
    <div className={containerClasses}>
      <div className='row fast-track-image-upload-row'>
        <div className='col-md-4'>
          <div className='image-upload'>
            <div className="fast-track-upload-img-wrap">
              <FilePicker
                extensions={extensions}
                onChange={onChange}
                onError={onError}
              >
                <div className="fast-track-upload-img-wrap">
                  {image &&
                    <div className='image-upload' style={{backgroundImage: `url(${image})`}}> </div>
                  }
                </div>
              </FilePicker>
            </div>
          </div>
        </div>

        <div className='col-md-8' >
          <label className='control-label _no-margin _bold'>{label}</label><br/>
          {note}
          {error && <small className="help-block _no-margin">{error}</small> }
          <FilePicker
            extensions={extensions}
            onChange={onChange}
            onError={onError}
          >
            <button className='btn btn-default margin-top margin-bottom side-bar'>Select image</button>
          </FilePicker>
          <p className='side-bar'>Accepted formats: JPG, PNG.<br/> Maximum file size: 5MB</p>
        </div>
      </div>
    </div>
  )
}

ImageUpload.defaultProps = {
  extensions: ['jpg', 'jpeg', 'png']
}
