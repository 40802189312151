import React from 'react'
import cx from 'classnames'
import moment from 'moment'

const INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const PICKER_FORMAT = 'YYYY-MM-DD'
const RENDER_FORMAT = 'DD/MM/YYYY'

function DateInput ({ name, onChange, value, editable, error }) {
  const dateValue = value
    ? value.date ? value.date : value
    : null
  const parsed = dateValue ? moment(dateValue, INPUT_FORMAT) : null
  if (!editable) return parsed && parsed.isValid() ? parsed.format(RENDER_FORMAT) : ''

  const handleChange = e => {
    const date = moment(e.target.value, PICKER_FORMAT)
    onChange(name, { ...value, date: date.isValid() ? date.format(INPUT_FORMAT) : '' })
  }

  return (
    <div className={cx(error && 'has-error', 'input__container--text')}>
      <input
        className={'input__control'}
        onChange={handleChange}
        value={value === null ? '' : parsed.format(PICKER_FORMAT)}
        type="date"
      />
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default DateInput
