import { WORDS } from '../constants'

/**
 * Takes a number between 1 & 6 and converts it to the equivalent word.
 * i.e. if the number 1 is passed, the word 'one' will be returned.
 *
 * @param {number} a number between 1 & 6
 * @returns {string} the equivalent word of number passed
 */

const numberToWord = num => WORDS[num] || num

export default numberToWord
