export const parseParams = paramString => {
  if (paramString[0] === '?') paramString = paramString.substring(1)
  if (!paramString) return {}

  return paramString.split('&').reduce((curr, attr) => {
    const [key, val] = attr.split('=').map(value => decodeURIComponent(value))
    return {
      ...curr,
      [key]: val,
    }
  }, {})
}

export const renderParams = object => {
  const items = Object.entries(object).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  return `?${items.join('&')}`
}
