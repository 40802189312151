import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SVG from '../../configurator/components/SVG'

const stagePropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  shortText: PropTypes.string,
  icon: PropTypes.string.isRequired,
})

export default class JourneyBar extends Component {

  static propTypes = {
    active: PropTypes.string.isRequired,
    stages: PropTypes.arrayOf(stagePropType).isRequired
  }

  render () {
    const { active, stages } = this.props
    const activePosition = stages.map(stage => stage.key).indexOf(active)
    return (
      <div className="journey-bar">
        {stages.map((stage, index) => {
          const className = classnames('journey-bar-stage', {
            'journey-bar-stage-complete': index < activePosition,
            'journey-bar-stage-active': index === activePosition
          })
          const iconName = index < activePosition ? 'complete' : stage.icon

          return (
            <div
              className={className}
              key={stage.key}
            >
              <div className="journey-bar-stage-icon-wrap">
                <div className="journey-bar-stage-icon">
                  <SVG name={iconName} />
                </div>
              </div>
              <div className="progress-bar" />
              <span className="visible-md visible-lg margin-top">{stage.text}</span>
              <span className="hidden-md hidden-lg margin-top">
                {stage.shortText ? stage.shortText : stage.text}
              </span>
            </div>
          )
        })}
      </div>
    )
  }
}
