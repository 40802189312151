import parseJsonApi from './parseJsonApi'

/**
 * Base class to help converting data from the api.
 *
 * This handles taking the raw response from the network request and converting it
 * into what is needed for the app.
 *
 * Usage:
 * To transform the api data you need to pass in your processResource function into this
 * transform function. Your processResource function will be given a single
 * resource from the api. So if the api returns an array of resources you will receive
 * a call with the POJO for each resource in that array.
 *
 * e.g.
 * processResource (data) {
 *    delete data.exampleProperty
 *
 *    return data
 * }
 *
 * This function will return another function that is needed to be passed into the promise
 * chain of the network request.
 *
 * e.g.
 * import transform from './transform'
 * const transformProduct = transform(processResource)
 *
 * api.post()
 *  .then(checkStatus)
 *  .then(transformProduct)
 */
export default function transform (processResource) {
  return (data) => {
    let parsedData = parseJsonApi(data)
    if (!parsedData) {
      return null
    }

    if (Array.isArray(parsedData)) {
      return parsedData.map((data) => {
        let resource = {
          ...data
        }

        return processResource(resource)
      })
    }

    let resource = {
      ...parsedData
    }

    return processResource(resource)
  }
}
