import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Screen extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  }

  componentDidMount () {
    smoothScrollTo(this.scrollTarget)
  }

  render () {
    const { children, className } = this.props
    return (
      <section className={classNames('band', className)} ref={ref => (this.scrollTarget = ref)}>
        <div className={'band-body'}>
          {children}
        </div>
      </section>
    )
  }
}
