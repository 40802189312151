import React from 'react'

const Steps = ({ stepNumber, stepHeader, stepDescription, stepIcon }) => (
  <div className='e-column-4 grid-flex'>
    <div className='how-it-works-item box box-flex'>
      <div className='box-body'>
        <img className='how-it-works-modal-img' src={stepIcon} width='75' alt="" />
        <h3 className='how-it-works-title -size-xl'><span className='how-it-works-number'>{stepNumber + 1}.</span> {stepHeader}</h3>
        <p className='how-it-works-text'>{stepDescription}</p>
      </div>
    </div>
  </div>
)

export const StepsContainer = ({ header, children }) => {
  return (
    <>
      <div className='text'>
        <h2 className='content--headline'>{header}</h2>
      </div>
      <div className='e-grid'>
        {children}
      </div>
    </>
  )
}

export default Steps
