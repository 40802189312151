import React from 'react'
import FrokModal from '../shared/components/FrokModal.js'

export default class SerialNumberHelpModal extends React.Component {
  render () {
    return (
      <FrokModal
        id="modalSerialNumberHelp"
        headline="How to find the serial number label"
        secondaryButton="Close"
        // noBody
      >
        <div>
          <p>For all appliance types refer to your Benchmark logbook, this is either a separate document or it is contained within the Installation &amp; Service Manual that your installer should have completed and left with you. The Serial number can be located on a data label. The serial number will be made up of up to 23 digits. The product serial number is 10 digits long and will start with 77.</p>
          <p>Do not remove any screws to look for the data label.</p>
          <p>Consider your safety and do not look for a data label if your appliance has been installed with restricted access or requires steps or ladders to reach.</p>
          <div className="table__overflow"></div>
          <table className="m-table">
            <thead>
            <tr>
              <th width="25%">Type of product</th>
              <th>Where to look</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Gas boiler</td>
              <td>The data label can often be found by lowering the boiler front panel flap down.</td>
            </tr>
            <tr>
              <td>Oil boiler</td>
              <td>The data label can be found within the oil boiler casing. Your oil boiler will also have its product type and output displayed on the control panel.</td>
            </tr>
            <tr>
              <td>Controls</td>
              <td>Please seek professional help from a qualified installer to remove the control and reveal the serial number.</td>
            </tr>
            <tr>
              <td>Solar</td>
              <td>Refer to your Benchmark logbook or installation documentation.</td>
            </tr>
            <tr>
              <td>Heat pump</td>
              <td>For all heat pumps, please refer to your Benchmark logbook or installation documentation.</td>
            </tr>
            <tr>
              <td>Cylinder</td>
              <td>The serial number can be found on the label located directly on the front of the cylinder.</td>
            </tr>
              <tr>
                <td>Bundles</td>
                <td>The serial number and label can be found on the side of the System Care Pack.</td>
              </tr>
            </tbody>
          </table>
          <p>The Gas Council number can be found on the front cover of Worcester Bosch installation manuals and on the boiler data label.</p>
        </div>
      </FrokModal>
    )
  }
}
