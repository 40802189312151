import React from 'react'
import classnames from 'classnames'
import TextInput from './TextInput'

export default class AddressFields extends React.Component {

  static defaultProps = {
    errors: {}
  }

  handleFieldChange = field => value => {
    const { onChange, address } = this.props
    const updated = {
      ...address,
      [field]: value
    }
    onChange && onChange(updated)
  }

  renderUnitHouseBuildingError () {
    const { errors } = this.props
    if (!errors.unitHouseBuilding) return null
    return (
      <div className={'text-center'}>
        <small className="help-block">{errors.unitHouseBuilding}</small>
      </div>
    )
  }

  render () {
    const { address: { unitNumber, buildingName, houseNumber, street, district, city, postcode }, errors } = this.props
    return (
      <div>
        <div className={classnames('grid grid-three-col', { 'has-error': errors.unitHouseBuilding })}>
          <div className='grid-col'>
            <TextInput
              label={'Unit Number'}
              name={'unitNumber'}
              value={unitNumber}
              onChange={this.handleFieldChange('unitNumber')}
            />
          </div>
          <div className='grid-col'>
            <TextInput
              label={'Building Name'}
              name={'buildingName'}
              value={buildingName}
              onChange={this.handleFieldChange('buildingName')}
            />
          </div>
          <div className='grid-col'>
            <TextInput
              label={'House Number'}
              name={'houseNumber'}
              value={houseNumber}
              onChange={this.handleFieldChange('houseNumber')}
            />
          </div>
          {this.renderUnitHouseBuildingError()}
        </div>
        <div className={'grid grid-two-col'}>
          <div className='grid-col'>
            <TextInput
              label={'Street'}
              required={true}
              name={'street'}
              value={street}
              onChange={this.handleFieldChange('street')}
              error={errors.street}
            />
          </div>
          <div className='grid-col'>
            <TextInput
              label={'District'}
              name={'district'}
              value={district}
              onChange={this.handleFieldChange('district')}
              error={errors.district}
            />
          </div>
        </div>
        <div className={'grid grid-two-col'}>
          <div className='grid-col'>
            <TextInput
              label={'City'}
              required={true}
              name={'city'}
              value={city}
              onChange={this.handleFieldChange('city')}
              error={errors.city}
            />
          </div>
          <div className='grid-col'>
            <TextInput
              label={'Postcode'}
              required={true}
              name={'postcode'}
              value={postcode}
              onChange={this.handleFieldChange('postcode')}
              error={errors.postcode}
            />
          </div>
        </div>
      </div>
    )
  }
}
