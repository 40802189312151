import { string, object } from 'yup'

const required = '{label} is required'

export const schema = object().shape({
  unitNumber: string().nullable(),
  buildingName: string().nullable(),
  houseNumber: string().nullable(),
  street: string(required).nullable().required().label('Street'),
  city: string(required).required().label('City'),
  postcode: string(required).required().label('Postcode')
}).test(
  'unitHouseBuilding',
  'At least one of Unit Number, Building Name or House Number is required',
  function ({ unitNumber, houseNumber, buildingName }) {
    const path = `${this.path}.unitHouseBuilding`
    if ([unitNumber, houseNumber, buildingName].every(isEmpty)) return this.createError({ path })
    return true
  }
)

const isEmpty = value => !value

export default input => schema.validate(input, { abortEarly: false })
