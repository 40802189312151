import Presenter from '../../../shared/network/presenters/Presenter'
import removeNullProperties from '../../../shared/util/removeNullProperties'

export class AbTestsPresenter extends Presenter {}

AbTestsPresenter.prototype.type = 'ab-tests'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(AbTestsPresenter, Presenter)

export default data => AbTestsPresenter.render(removeNullProperties(data))
