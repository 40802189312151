import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class SVG extends React.Component {

  static get propTypes () {
    return {
      selected: PropTypes.bool,
      name: PropTypes.string.isRequired
    }
  }

  static get defaultProps () {
    return {
      selected: false
    }
  }

  render () {
    return (
      <svg
        className={classnames('svg-icon', {
          'svg-icon-success': this.props.selected
        })}
      >
        <use xlinkHref={'#icon-' + this.props.name}/>
      </svg>
    )
  }
}
