import React from 'react'
import FrokTextInput from './FrokTextInput'

export default class FrokAddressFields extends React.Component {

  static defaultProps = {
    errors: {}
  }

  handleFieldChange = field => value => {
    const { onChange, address } = this.props
    const updated = {
      ...address,
      [field]: value
    }
    onChange && onChange(updated)
  }

  renderUnitHouseBuildingError () {
    const { errors } = this.props
    if (!errors.unitHouseBuilding) return null
    return (
      <div className="a-notification a-notification--text -error" role="alert">
        <div className="a-notification__content">{errors.unitHouseBuilding}</div>
      </div>
    )
  }

  render () {
    const { address: { unitNumber, buildingName, houseNumber, street, district, city, postcode }, errors } = this.props
    return (
      <div>
        <div className="o-form__row">
          <FrokTextInput
            label={'Unit Number'}
            name={'unitNumber'}
            value={unitNumber}
            onChange={this.handleFieldChange('unitNumber')}
            colSize='-half'
          />
          <FrokTextInput
            label={'Building Name'}
            name={'buildingName'}
            value={buildingName}
            onChange={this.handleFieldChange('buildingName')}
            colSize='-half'
          />
          <FrokTextInput
            label={'House Number'}
            name={'houseNumber'}
            value={houseNumber}
            onChange={this.handleFieldChange('houseNumber')}
            colSize='-half'
          />
        </div>
          {this.renderUnitHouseBuildingError()}
        <div className="o-form__row">
          <FrokTextInput
            label={'Street'}
            required={true}
            name={'street'}
            value={street}
            onChange={this.handleFieldChange('street')}
            error={errors.street}
            colSize='-half'
          />
          <FrokTextInput
            label={'District'}
            name={'district'}
            value={district}
            onChange={this.handleFieldChange('district')}
            error={errors.district}
            colSize='-half'
          />
        </div>
        <div className="o-form__row">
          <FrokTextInput
            label={'City'}
            required={true}
            name={'city'}
            value={city}
            onChange={this.handleFieldChange('city')}
            error={errors.city}
            colSize='-half'
          />
          <FrokTextInput
            label={'Postcode'}
            required={true}
            name={'postcode'}
            value={postcode}
            onChange={this.handleFieldChange('postcode')}
            error={errors.postcode}
            colSize='-half'
          />
        </div>
      </div>
    )
  }
}
