/* eslint no-template-curly-in-string: 0 */
import { string } from 'yup'

// Deconstructs the phone number regex /^[0-9 +()]{5,20}$/

export default string()
  .nullable() // Nullable values should pass as empty strings
  .min(5, '${label} must be at least ${min} digits')
  .max(20, '${label} cannot be more than ${max} digits')
  .matches(/^[0-9 +()]*$/, '${label} contains invalid characters')
