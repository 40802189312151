export function trackEvent (action, label) {
  window.pushDataLayer({
    event: 'consumerPortal',
    action: action,
    label: label,
  })
}

export function trackClick (label) {
  trackEvent('click', label)
}
