import React from 'react'

import classNames from 'classnames'

const Icon = ({
  iconName,
  titleText = null,
  className = '',
  isUiIcon = false,
  style,
}) => {
  const classes = classNames('a-icon', {
    [className]: className,
    [`boschicon-bosch-ic-${iconName}`]: iconName && !isUiIcon,
    [`ui-ic-${iconName}`]: iconName && isUiIcon,
  })

  return (
    <i className={classes} title={titleText || iconName} style={style} />
  )
}

export default Icon
