import api from '../../shared/network/api'
import prepareBasketItem from '../presenters/BasketItemPresenter'
import parseBasket from '../transfomers/parseBasket'

export default item => api
  .post(
    '/basket-items',
    prepareBasketItem(item)
  )
  .then(parseBasket)

