import React, { useState } from 'react'

const HoverCards = ({ cards, title }) => {
  const [activeCard, setActiveCard] = useState(0)

  if (!cards || cards.length === 0) return null

  return (
    <section className='landing-page-hover-cards default-section-spacing m-banner__background'>
      {title && (
        <header className='landing-page-hover-cards__header'>
          <div className='e-container text-center'>
            <h2 className='landing-page-hover-cards__title'>{title}</h2>
          </div>
        </header>
      )}

      <div className='landing-page-hover-cards__main e-container'>
        <div className='landing-page-hover-cards__content-container landing-page-hover-cards__content-container--image'>
          {cards &&
            cards.map((card, index) => {
              if (!card?.image?.src) {
                return null
              }

              return (
                <picture
                  key={index}
                  className={`landing-page-hover-cards__image${index === activeCard ? ' active' : ''}`}
                >
                  <img src={card.image.src} className='landing-page-hover-cards__card-image' alt={card.image.alt} />
                </picture>
              )
            })}
        </div>
        <div className='landing-page-hover-cards__content-container landing-page-hover-cards__content-container--text'>
          {cards &&
            cards.map((card, index) => {
              if (!card.title && !card.text) {
                return null
              }

              return (
                <div
                  key={index}
                  className={`landing-page-hover-cards__card${index === activeCard ? ' active' : ''}`}
                  onMouseEnter={() => setActiveCard(index)}
                >
                  {card.title && <h3 className='landing-page-hover-cards__card-title'>{card.title}</h3>}
                  {card.text && (
                    <p className='landing-page-hover-cards__card-text paragraph-medium-desktop'>{card.text}</p>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default HoverCards
