export function trackPageView (page = window.location.pathname + window.location.search) {
  window.pushDataLayer({
    event: 'pageview',
    action: 'view',
    label: page,
  })
}

export function trackEvent (action, label) {
  window.pushDataLayer({
    event: 'configurator',
    action: action,
    label: label,
  })
}

export function trackConversion (label) {
  window.pushDataLayer({
    event: 'conversion',
    action: 'Opportunity Generated',
    label: label,
  })
}
