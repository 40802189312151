import React, { useState, useEffect } from 'react'
import Modal from '../../../lifestyle/components/Modal'
import logBenchmarkOnboarding from '../../network/requests/logBenchmarkOnboarding'
import ConnectNow from './ConnectNow'
import ConfirmCreation from './ConfirmCreation'

export const CONTENT_STATE = {
  INTRO: 'intro',
  CONNECT_LATER: 'connect-later',
  LETS_CONNECT: 'lets-connect',
  CONFIRM_CREATION: 'confirm-creation',
  CONNECT_NOW: 'connect-now',
  ACCOUNTS_CONNECTED: 'accounts-connected',
  ENGINEER_CONNECT: 'engineer-connect',
}

function InstallerOnboardingModal (props) {
  const { tcLink, onClose = () => null, initialContentState = CONTENT_STATE.INTRO } = props

  const [isOpen, setIsOpen] = useState(true)
  const [hasConnected, setHasConnected] = useState(false)
  const [contentState, setContentState] = useState(initialContentState)

  const handleClose = () => {
    setIsOpen(false)
    onClose(hasConnected)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const renderContent = () => {
    switch (contentState) {
      case CONTENT_STATE.INTRO:
        return (
          <div className="onboarding__content--installer">
            <h3 className="onboarding__title--installer -size-xl m-t-0">Benchmark Checklist submission is now available</h3>
            <p className="onboarding__text--installer">
              You can now complete and submit your Benchmark Checklist as part of a Worcester Bosch guarantee
              registration. To complete your submissions you will need to connect your Worcester Bosch loyalty and Benchmark accounts. This only takes two minutes to complete.
            </p>
            <div className="onboarding__buttons">
              <button type="button" className="a-button a-button--primary -without-icon" onClick={() => setContentState(CONTENT_STATE.CONNECT_LATER)}>
                <span className="a-button__label">Connect accounts later</span>
              </button>
              <button type="button" className="a-button a-button--primary -without-icon" onClick={() => setContentState(CONTENT_STATE.LETS_CONNECT)}>
                <span className="a-button__label">Connect accounts now</span>
              </button>
            </div>
          </div>
        )
      case CONTENT_STATE.CONNECT_LATER:
        return (
          <div className="onboarding__content--installer">
            <h3 className="onboarding__title--installer -size-xl m-t-0">Connect your accounts later</h3>
            <p className="onboarding__text--installer">
              You may continue using your portal and registering guarantees as normal. To connect your accounts later
              you can either begin registering a guarantee and selecting that you wish to complete the Benchmark
              Checklist. Alternatively visit My Account and open the My Applications section, where you will find the
              option to connect your accounts.
            </p>
            <div className="onboarding__buttons onboarding__buttons--right">
              <button
                className="a-button a-button--primary -without-icon"
                onClick={() => {
                  logBenchmarkOnboarding()
                  handleClose()
                }}
              >
                <span className="a-button__label">Continue</span>
              </button>
            </div>
          </div>
        )
      case CONTENT_STATE.LETS_CONNECT:
        return (
          <div className="onboarding__content--installer">
            <h3 className="onboarding__title--installer -size-xl m-t-0">Let&apos;s connect your accounts</h3>
            <p className="onboarding__text--installer">
              We need to connect your Worcester Bosch loyalty and Benchmark accounts, so that we can pass your completed checklists to HHIC.
              If you already have an account, select below and log in with your Benchmark username and password when prompted.
              If you don&apos;t have an account yet, select below and then accept the terms and conditions to continue.
              An account will be automatically created using your username and connected to your loyalty account.
            </p>
            <div className="onboarding__buttons onboarding__buttons--top">
              <div className="a-link a-link--button onboarding__button--connect">
                <a onClick={() => setContentState(CONTENT_STATE.CONNECT_NOW)}>
                  <span>I already have a Benchmark account</span>
                </a>
              </div>
              <br/>
              <div className="a-link a-link--button-secondary onboarding__button--connect">
                <a
                  onClick={() => {
                    window.open(tcLink, '_blank')
                    setContentState(CONTENT_STATE.CONFIRM_CREATION)
                  }}>
                  <span>I don’t have a Benchmark account</span>
                </a>
              </div>
            </div>
          </div>
        )
      case CONTENT_STATE.CONFIRM_CREATION:
        return (
          <ConfirmCreation
            setNextContent={setContentState}
            closeModal={handleClose}
            onConnect={() => setHasConnected(true)}
          />
        )
      case CONTENT_STATE.CONNECT_NOW:
        return (
          <ConnectNow
            setNextContent={setContentState}
            closeModal={handleClose}
            onConnect={() => setHasConnected(true)}
          />
        )
      case CONTENT_STATE.ACCOUNTS_CONNECTED:
        return (
          <div className="onboarding__content--installer">
            <h3 className="onboarding__title--installer -size-xl m-t-0">Your accounts are now connected</h3>
            <p className="onboarding__text--installer">
              Congratulations, you have now connected your loyalty and Benchmark accounts. To complete a checklist
              simply begin registering a guarantee for a qualifying product as normal. Then, when asked, select that you
              would like to complete the Benchmark Checklist as part of your guarantee registration.
            </p>
            <div className="a-link a-link--button-secondary onboarding__buttons onboarding__buttons--top">
              <a
                className="onboarding__button--connect"
                onClick={() => {
                  logBenchmarkOnboarding()
                  handleClose()
                }}
              >
                <span>Continue</span>
              </a>
            </div>
          </div>
        )
      case CONTENT_STATE.ENGINEER_CONNECT:
        return (
          <div className="onboarding__content--installer">
            <h3 className="onboarding__title--installer -size-xl m-t-0">Connect accounts</h3>
            <p className="onboarding__text--installer">
              The Excelerate and Benchmark accounts are not currently connected. Please contact your master account holder
              to establish this connection. This step is necessary for passing your completed checklists to HHIC.
            </p>
            <div className="onboarding__buttons onboarding__buttons--top">
              <div className="a-link a-link--button onboarding__button--connect">
                <a onClick={() => handleClose()}>
                  <span>Close</span>
                </a>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="onboarding__modal--container!hhic">
      <Modal isOpen={isOpen} displayHeader={false}>
        <div className="onboarding__container--modal">
          <div className='onboarding__slide'>
            {renderContent()}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InstallerOnboardingModal
