import React, { useRef, useState } from 'react'

import classNames from 'classnames'
import HTMLReactParser from 'html-react-parser'

import Icon from '../Icon'

const Accordion = ({ children, content, headline, id, size = 'large' }) => {
  const accordionRef = useRef()

  const [open, setOpen] = useState()

  const classes = classNames('a-accordion', {
    'a-accordion--small': size === 'small',
  })

  const toggleAccordion = () => {
    if (accordionRef.current.hasAttribute('open')) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  return (
    <details className={classes} ref={accordionRef}>
      <summary
        className="a-accordion__headline"
        aria-controls={`panel-${id}`}
        aria-expanded={open ? 'true' : 'false'}
        onClick={toggleAccordion}
      >
        <span className="a-accordion__headline-text highlight">{headline}</span>
        <Icon iconName={open ? 'up' : 'down'} className="a-accordion__headline-icon" />
      </summary>
      <div className="a-accordion__content" id={`panel-${id}`}>
        {children || HTMLReactParser(content)}
      </div>
    </details>
  )
}

export default Accordion
