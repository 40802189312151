const Constants = {}

Constants.BREAKPOINT_MD_MIN = 768
Constants.BREAKPOINT_MD_MAX = Constants.BREAKPOINT_MD_MIN - 1

export const TITLES = ['Mr', 'Mrs', 'Ms', 'Miss', 'Doctor', 'Sir', 'Professor', 'Other']

export const CUSTOMER_STATUSES = ['Owner', 'Landlord', 'Tenant', 'Housing Association']

export const INSTALLER_TYPES = ['installer', 'merchant', 'specifier']

// Steps.js
export const WORDS = ['zero', 'one', 'two', 'three', 'four', 'five', 'six']

// AutoComplete.js
export const NO_RESULTS = -1
export const NO_RESULTS_TEXT = 'No results found...'

// CapturePlusInput.js
// -  GBR,IRL,IMN,JEY,GGY
export const COUNTRIES = 'GB,IE,IM,JE,GG'
export const IE_CODE = 'IE'
export const LIMIT = 10
export const FIND_PCA_URL = '/api/v3/loqate'
export const RETRIEVE_PCA_URL = '/api/v3/loqate/address'

// CleanModal.js
export const BODY_CLASS_ARGS = ['body', 'modal-open']

// Select.js
export const DESELECT = '__DESELECT'

export default Constants
