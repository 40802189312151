import Presenter from './Presenter'
import { AddressPresenter } from './AddressPresenter'
import { EnquiryPresenter } from './EnquiryPresenter'
import { AbTestsPresenter } from '../../../configurator/network/presenters/AbTestsPresenter'

export class SalesLeadPresenter extends Presenter {
  relationships () {
    return {
      address: AddressPresenter,
      'installation-enquiry': EnquiryPresenter,
      'ab-tests': AbTestsPresenter
    }
  }
}

SalesLeadPresenter.prototype.type = 'sales-lead'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(SalesLeadPresenter, Presenter)

export default data => SalesLeadPresenter.render(data)
