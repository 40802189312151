import React from 'react'
import DisplayHtml from './FormRowFields/DisplayHtml'
import Text from './FormRowFields/Text'
import URL from './FormRowFields/URL'
import TextArea from './FormRowFields/TextArea'
import NumberInput from './FormRowFields/Number'
import Toggle from './FormRowFields/Toggle'
import List from './FormRowFields/List'
import ImageCheckbox from './FormRowFields/ImageCheckbox'
import Checkbox from './FormRowFields/Checkbox'
import Image from './FormRowFields/Image'
import AddressWithRegistered from './FormRowFields/AddressWithRegistered'
import Address from './FormRowFields/Address'
import Heading from './FormRowFields/Heading'
import CheckboxCards from './FormRowFields/CheckboxCards'
import WYSIWYG from './FormRowFields/WYSIWYG'
import DateInput from './FormRowFields/DateInput'
import Select from './FormRowFields/Select'
import RadioButton from './FormRowFields/RadioButton'

const fields = {
  addressWithRegistered: AddressWithRegistered,
  address: Address,
  boolean: Toggle,
  checkbox: Checkbox,
  html: DisplayHtml,
  radio: RadioButton,
  checkboxcards: CheckboxCards,
  date: DateInput,
  heading: Heading,
  imageCheckbox: ImageCheckbox,
  image: Image,
  list: List,
  number: NumberInput,
  password: Text,
  select: Select,
  text: Text,
  fixed: null,
  textarea: TextArea,
  wysiwyg: WYSIWYG,
  url: URL
}

export default function FormRow ({ label, type, ...props }) {
  const Component = fields[type]
  if (Component === undefined) {
    console.warn('Unknown component type:', type)
    return null
  }

  if (Component === null) {
    return null
  }

  if (Component === Heading) {
    return <div className="details__row"><Component label={label} /></div>
  }

  const { helpText } = props
  return (

    <tr>
      <td width="30%">
        <strong>{label}</strong>
        {helpText && (
          <p className="details__subheader">{helpText}</p>
        )}
      </td>
      <td><Component {...props} /></td>
    </tr>

    // <div className="details__row">
    //   <div className="details__col--label">
    //     <div className="details__header">
    //       {label}
    //     </div>
    //     {helpText && (
    //       <p className="details__subheader">{helpText}</p>
    //     )}
    //   </div>
    //   <div className="details__col--value">
    //     <div className="details__value">
    //       <Component {...props} />
    //     </div>
    //   </div>
    // </div>
  )
}
