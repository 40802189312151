import React from 'react'
import PropTypes from 'prop-types'

export default function ProductInfoItems ({features}) {
  return (
    <div className="row">
      <div className="express-tool-product-info configurator-tool-product-info">
        {features.map((feature, index) => (
          <div
            key={index}
            className="product-info-item"
          >
            <div className={feature.colour}/>
            <span>{feature.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

ProductInfoItems.propTypes = {
  features: PropTypes.array.isRequired,
}
