import React, { createRef } from 'react'
import { get } from '../network/get'
import { COUNTRIES, IE_CODE, FIND_PCA_URL, RETRIEVE_PCA_URL } from '../constants'
import toWorcesterAddress from '../util/pca-to-worcester-address'
import debounce from '../util/debounce'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

export default class CapturePlusInput extends React.Component {
  static defaultProps = {
    placeholder: 'Search your address',
    onError: () => {},
    onChange: () => {},
    error: null,
    value: '',
    style: {},
    hideResultsOnBlur: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      value: props.value,
      address: props.address,
      results: [],
      loading: false,
      error: null,
      isFocused: false,
      isFirstResultItemFocused: false,
    }

    // Set the default display text to an existing address if we've been passed one
    if (!isEmpty(props.address)) {
      const { buildingName, unitNumber, houseNumber, street, district, localDistrict, city, postcode } = props.address
      const stringAddress = `${buildingName || unitNumber || houseNumber || ''} ${street || ''} ${[
        localDistrict || district,
        city,
        postcode,
      ]
        .filter(Boolean)
        .join(', ')}`
      this.state.value = stringAddress
    }

    this.firstResultItem = createRef()
  }

  getCountries () {
    if (window.IS_IRISH === '1') {
      return IE_CODE
    }
    return COUNTRIES
  }

  handleInputChange = value => {
    // deliberately called with no value to reset the state in the calling scene,
    // the search is only valid when a final value is selected
    this.props.onChange()

    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }

    this.setState({
      value,
      loading: !!value,
      error: null,
    })
    if (value) this.getSearchResultsForTerm(value)
  }

  handleClearInput = () => {
    this.props.onChange()
    this.setState({ value: '', results: [] })
  }

  // If the Type is 'Address' then the 'Id' can be passed to the 'Retrieve' service.
  // Any other 'Id' should be passed as the 'Container' to a further 'Find' request to get more results.
  selectResult = item => {
    if (item.type === 'Address') return this.getFullAddress(item)
    return this.getSearchResultsForItem(item)
  }

  parseResponse = response => {
    return response.Items.map(item => {
      const parsed = Object.keys(item).reduce((keys, key) => {
        keys[key.toLowerCase()] = item[key]
        return keys
      }, {})
      if (parsed.error) {
        parsed.text = 'Error -'
        parsed.id = parsed.error
      }
      return parsed
    })
  }

  getSearchResultsForTerm = debounce(value => {
    return get(FIND_PCA_URL, {
      query: value,
      country: this.getCountries(),
      raw: true,
    })
      .then(this.handleSearchResults)
      .catch(this.handleAjaxErrors)
  }, 500)

  getSearchResultsForItem = item => {
    return get(FIND_PCA_URL, {
      country: this.getCountries(),
      group: item.id,
      raw: true,
    })
      .then((response) => this.handleSearchResults(response, true))
      .catch(this.handleAjaxErrors)
  }

  handleSearchResults = (response, focusFirstItem) => {
    const results = this.parseResponse(response)
    this.setState({
      results,
      loading: false,
      error: null,
    })

    if (focusFirstItem && this.firstResultItem?.current) {
      this.firstResultItem.current.focus()
      this.setState({
        isFirstResultItemFocused: true
      })
    } else {
      this.setState({
        isFirstResultItemFocused: false
      })
    }
  }

  getFullAddress = item => {
    return get(RETRIEVE_PCA_URL, {
      id: item.id,
      raw: true,
      include: '{Latitude},{Longitude}',
    })
      .then(toWorcesterAddress)
      .then(results => {
        const value = `${item.text} ${item.description}`
        const address = results // The first returned address
        this.setState({
          value,
          address,
          results: null,
          loading: false,
          error: null,
        })
        this.props.onChange(address, value)
      })
      .catch(this.handleAjaxErrors)
  }

  handleAjaxErrors = () => {
    this.props.onError()
    this.setState({
      error: "Please check your internet connection. Can't search at this time.",
      loading: false,
    })
  }

  resultsKeyDownHandler = (e, itemData) => {
    if (!e?.target || !e.key) {
      return
    }

    const isNextRequest = e.key === 'ArrowDown'
    const isPrevRequest = e.key === 'ArrowUp'

    if (isNextRequest || isPrevRequest) {
      e.preventDefault()
      const resultsItems = Array.from(e.target?.closest('ul')?.querySelectorAll('li'))

      if (!resultsItems?.length === 0) {
        return
      }

      let targetItem
      const nextItem = resultsItems[resultsItems.indexOf(e.target) + 1]
      const prevItem = resultsItems[resultsItems.indexOf(e.target) - 1]

      if (isNextRequest) {
        targetItem = nextItem ?? resultsItems[0]
      } else {
        targetItem = prevItem ?? resultsItems[resultsItems.length - 1]
      }

      targetItem.focus()
    }

    if (e.key === 'Tab' || e.key === 'Escape') {
      this.setState({
        results: [],
      })
    }

    if (e.key === 'Enter') {
      this.selectResult(itemData)
    }
  }

  renderResults () {
    const { isFocused, results, value } = this.state
    const noResults = !results || !results.length
    const hiddenOnBlur = this.props.hideResultsOnBlur && !isFocused
    if (noResults || hiddenOnBlur || value?.length === 0) return null
    return (
      <ul className='capture-plus-results' tabIndex='-1'>
        {results.map((item, i) => {
          const classes = classNames({'focus-ring focus-visible': i === 0 && this.state.isFirstResultItemFocused})

          return (
            <li
              key={item.id}
              ref={i === 0 ? this.firstResultItem : null}
              className={classes}
              tabIndex={i === 0 ? 0 : -1}
              onClick={() => this.selectResult(item)}
              onKeyDown={e => this.resultsKeyDownHandler(e, item)}
            >
              <strong>{item.text}</strong> <span>{item.description}</span>
            </li>
          )
        })}
      </ul>
    )
  }

  render () {
    const { placeholder, style, clearButton } = this.props
    return (
      <div className="capture-plus-container a-text-field a-text-field--search" style={style}>
        <input
          ref={this.props.inputRef}
          type='text'
          className='v2wb-input capture-plus-input form-field'
          onChange={event => this.handleInputChange(event.target.value)}
          value={this.state.value}
          placeholder={placeholder}
          onKeyUp={event => {
            if (event.key === 'Escape') event.target.blur()
          }}
          onBlur={() => this.setState({ isFocused: false })}
          onFocus={() => this.setState({ isFocused: true })}
        />

        {(clearButton?.props?.text || clearButton?.iconClassName) && this.state.value.length > 0 && (
          <button
            {...this.props.clearButton.props}
            type='button'
            onClick={this.handleClearInput}
            title='Delete address'
          >
            {clearButton.props.text && <span>{clearButton.props.text}</span>}
            {clearButton.iconClassName && <span className={clearButton.iconClassName}></span>}
          </button>
        )}

        {clearButton?.inactiveIconClassName && this.state.value.length === 0 && (
          <span className={clearButton.inactiveIconClassName}></span>
        )}

        {this.renderResults()}
      </div>
    )
  }
}
