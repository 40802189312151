import React from 'react'
import Collapse from 'react-bootstrap/es/Collapse'

export default class FixedMenu extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      expanded: props.defaultExpanded
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.defaultExpanded !== null) {
      this.setState({ expanded: nextProps.defaultExpanded })
    }
  }

  toggleExpanded () {
    this.setState({ expanded: !this.state.expanded })
  }

  handleToggle = e => {
    e.preventDefault()
    this.toggleExpanded()
  }

  render () {
    var toggle = this.props.toggle || false
    var btn = this.props.btn || false
    var expanded = this.state.expanded

    var body = (
      <div className="fixed-menu-body">
        {this.props.children}
      </div>
    )

    if (toggle) {
      var buttonClass = 'fixed-menu-toggle fixed-menu-btn a-button a-button--secondary'
      if (expanded) {
        buttonClass += ' collapsed'
      }
      body = (
        <div>
          <button
            onClick={this.handleToggle}
            className={buttonClass}
            type="button"
          >
            <i className={`a-icon a-button__icon ${expanded ? 'boschicon-bosch-ic-down-small' : 'boschicon-bosch-ic-up-small'}`}></i>
            <span className="a-button__label">
              {(expanded) ? 'Hide' : 'Show'}
              <span className="sr-only"> Panel Content</span>
            </span>
          </button>
          <Collapse in={expanded}>
            {body}
          </Collapse>
        </div>
      )
    }

    return (
      <div className="fixed-menu">
        {body}
        {btn}
      </div>
    )
  }
}
