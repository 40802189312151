import React from 'react'
import PropTypes from 'prop-types'

const LoadingOverlay = props => {

  const showBox = () => {
    const { description, spinner, title } = props
    return description || spinner || title
  }

  const renderSpinner = () => {
    const { spinner } = props
    if (!spinner) return null
    return <div className='circle-spinner' style={styles.spinner} />
  }

  const renderBox = () => {
    if (!showBox) return null
    const { description, title } = props
    return (
      <div className='box' style={styles.box}>
        {renderSpinner()}
        {title && <h2>{title}</h2>}
        {description && <p style={styles.description}>{description}</p>}
      </div>
    )
  }

  return (
    <div style={styles.container}>
      {renderBox()}
    </div>
  )
}

LoadingOverlay.propTypes = {
  description: PropTypes.string,
  spinner: PropTypes.bool,
  title: PropTypes.string
}

LoadingOverlay.defaultPropTypes = {
  spinner: true
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
  },
  box: {
    padding: '36px',
    position: 'sticky',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  spinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '12px'
  },
  description: {
    margin: 0,
    marginTop: '50px',
  }
}

export default LoadingOverlay
