import React, { createRef } from 'react'
import changePath from '../network/path'

const Hero = ({
  imageUrl,
  header,
  body,
  buttonText,
  buttonUrl,
  linkText,
  linkUrl,
  buttonAnalytics,
  linkAnalytics,
}) => {
  const buttonRef = createRef()
  const handleClick = func => changePath(func)

  return (
    <div className='e-grid'>
      <div className='e-column-6 vertical-center vertical-padding-lg vertical-middle'>
        <div>
          <h1 className='content--headline'>{header}</h1>
          <div dangerouslySetInnerHTML={{__html: body}} />
          {buttonUrl && (
            <button
              className='a-button a-button--primary -without-icon margin-bottom'
              onClick={() => handleClick(buttonUrl)}
              ref={linkUrl && buttonRef}
              {...buttonAnalytics}
            >
              {buttonText}
            </button>
          )}
          &nbsp;
          {linkUrl && (
            <button
              className='a-button a-button--tertiary -without-icon margin-bottom'
              onClick={() => handleClick(linkUrl)}
              {...linkAnalytics}
            >
              {linkText}
            </button>
          )}
        </div>
      </div>
      <div className='e-column-6'>
        <img src={imageUrl} alt='' className='a-image-cover' />
      </div>
    </div>
  )
}

export default Hero
