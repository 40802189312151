import React from 'react'
import PropTypes from 'prop-types'
import FrokNotification from './FrokNotification'

const TextInput = ({
  type = 'text',
  name,
  label,
  value,
  placeholder,
  onBlur,
  uat,
  maxLength,
  subLabel,
  subLabelClass = '-neutral',
  error,
  onChange,
  required,
  className,
  colSize = '',
  disabled,
  loading = false,
  postContent,
}) => {
  const handleChange = event => {
    onChange(event.target.value)
  }

  return (
    <div className={`m-form-field m-form-field--${colSize} ${colSize} ${className}`}>
      <div className="a-text-field">
        <label htmlFor={name}>{label} {required && '*'}</label>
        <input
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value || ''}
          onChange={handleChange}
          onBlur={onBlur}
          {...(uat ? { 'data-uat': uat } : {})}
          maxLength={maxLength}
          disabled={disabled}
          style={{ paddingRight: postContent ? '3rem' : '1rem' }}
        />
        {loading && <div className="a-text-field__icon-search"><div className="loader" /></div>}
        {/* Frok doesn't really allow this, but it works if you put custom text in the search class */}
        {postContent && <div className="a-text-field__icon-search">{postContent}</div>}
      </div>
      {subLabel && <FrokNotification type={subLabelClass} noIcon>{subLabel}</FrokNotification>}
      {error && <FrokNotification type="-error" noIcon>{error}</FrokNotification>}
    </div>
  )
}

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  outerClass: PropTypes.string,
  maxLength: PropTypes.number,
  subLabel: PropTypes.string,
  subLabelClass: PropTypes.string,
  isFastTrackForm: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

TextInput.defaultProps = {
  error: undefined,
  required: false,
  uat: false,
  value: '',
  onChange: () => {},
  isFastTrackForm: false,
}

export default TextInput
