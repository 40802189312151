import isPlainObject from 'lodash/isPlainObject'
import snakeCase from 'lodash/snakeCase'
import camelCase from 'lodash/camelCase'

export const objectToCase = caseFn => {
  const actualFn = (object, nested = false) => {
    if (!isPlainObject(object)) return object
    const newObject = {}
    Object.entries(object).forEach(([key, value]) => {
      const newKey = caseFn(key)
      let newValue = value
      // Re-assign newValue if nested
      if (nested) {
        if (Array.isArray(value)) newValue = newValue.map(v => actualFn(v, nested))
        else if (isPlainObject(newValue)) newValue = actualFn(newValue, nested)
      }
      // Add this key to the object
      newObject[newKey] = newValue
    })
    return newObject
  }
  return actualFn
}

export const objectToSnake = objectToCase(snakeCase)
export const objectToCamel = objectToCase(camelCase)
