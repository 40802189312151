import nullEmpty from './nullEmpty'

export default pcaAddress => {
  // Destructure all the
  const {
    BuildingName,
    BuildingNumber,
    City,
    CountryIso3,
    Company,
    District,
    Field1,
    Field2,
    Id,
    Line1,
    PostalCode,
    Street,
    SubBuilding,
    POBoxNumber,
    Type,
  } = pcaAddress

  const address = {
    pcaId: Id,
    buildingName: null,
    unitNumber: null,
    houseNumber: null,
    city: City,
    district: District,
    street: Street || SubBuilding,
    postcode: PostalCode,
    latitude: Field1,
    longitude: Field2,
  }

  // decide what to do with buildingNumber
  if (Type === 'Commercial') {
    if (Company) address.buildingName = [BuildingName, Company].filter(Boolean).join(', ')
    address.unitNumber = BuildingNumber
  } else {
    address.buildingName = BuildingName
    address.houseNumber = BuildingNumber
  }

  if (POBoxNumber) {
    address.houseNumber = `PO Box ${POBoxNumber}`
  }

  // fallback to try and avoid a validation failure
  if (!address.buildingName && !address.unitNumber && !address.houseNumber) {
    if (Line1 !== address.street) {
      address.buildingName = Line1
    } else if (Company) {
      address.buildingName = Company
    }
  }

  // if we have a sub building and no unit number yet, use that
  if (SubBuilding && address.street !== SubBuilding && !address.unitNumber) {
    address.unitNumber = SubBuilding
  }

  address.isIrish = CountryIso3 === 'IRL'

  return nullEmpty(address)
}
