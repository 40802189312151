import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class FormGroup extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    isFastTrackForm: PropTypes.bool,
    hasHtmlLabel: PropTypes.bool,
  }

  static get defaultProps () {
    return {
      required: false,
      error: undefined,
      label: null,
      isFastTrackForm: false,
      hasHtmlLabel: false,
    }
  }

  renderLabel () {
    if (!this.props.label || this.props.label.length === 0) return
    return (
      <div>
        <label htmlFor={this.props.name} className={this.props.hasHtmlLabel ? 'control-label label-with-html' : 'control-label'}>
          {this.props.hasHtmlLabel ? <span dangerouslySetInnerHTML={{ __html: this.props.label }}></span> : this.props.label }
          {this.props.isFastTrackForm && !this.props.required && <span> (optional)</span>}
        </label>
      </div>
    )
  }

  renderError () {
    const { error, isFastTrackForm } = this.props
    if (error === undefined || error === '') return null
    return (
      <small className='help-block'>
        {isFastTrackForm && <span className='ui-ic-alert-error' />}
        {error}
      </small>
    )
  }

  render () {
    const { children, required, error, disabled, isFastTrackForm } = this.props
    const containerClasses = classnames('form-group', {
      'is-required': required,
      'has-error': error !== undefined,
      disabled,
    })
    return (
      <div className={containerClasses}>
        {this.renderLabel()}
        {isFastTrackForm ? <div className='fast-track-form__input-wrapper'>{children}</div> : children}
        {this.renderError()}
      </div>
    )
  }
}
