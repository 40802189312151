import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NO_RESULTS, NO_RESULTS_TEXT } from '../constants'

export default class AutoComplete extends React.Component {

  static get propTypes () {
    return {
      onChange: PropTypes.func
    }
  }

  static get defaultProps () {
    return {
      uat: false // User Acceptance Testing data attribute
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      hasFocus: false,
      value: this.props.value,
      suggestions: [],
      selectedIndex: 0,
      selectedText: null
    }
  }

  onSuggestionsReceived = suggestions => {
    // only deal with suggestions if we have focus
    if (!this.state.hasFocus) { return }
    var selected = 0
    if (suggestions.length === 0) {
      suggestions.push({
        index: NO_RESULTS,
        text: NO_RESULTS_TEXT
      })
      selected = NO_RESULTS
    } else {
      suggestions = suggestions.map(function (text, ix) {
        return {
          index: ix,
          text: text.name,
          url: text.url
        }
      })
    }
    this.setState({
      selectedIndex: selected,
      suggestions: suggestions
    })
  }

  onInputFocus = e => {
    this.setState({ hasFocus: true })
  }

  onInputBlur = e => {
    this.setState({ hasFocus: false })
  }

  onInputChange = e => {
    var value = e.target.value
    var state = { value: value }
    if (!value) { state.selectedText = null }
    this.setState(state)
    clearTimeout(this.typeTimeout)
    this.typeTimeout = setTimeout(() => this.findSuggestions(), 1000)
  }

  onInputKeyDown = e => {
    var selected = this.state.selectedIndex
    var suggestions = this.state.suggestions
    // special case for no results
    if (selected === NO_RESULTS) return
    switch (e.keyCode) {
      case 38: // up
        selected -= 1
        break
      case 40: // down
        selected += 1
        break
      case 13: // enter
        if (this.state.selectedText) {
          this.onSubmitClick()
        } else {
          this.selectSuggestion(this.state.selectedIndex)
        }
        break
      default:
        // no special action required
        return
    }

    if (selected < 0) {
      selected = suggestions.length - 1
    } else if (selected >= suggestions.length) {
      selected = 0
    }
    this.setState({
      selectedIndex: selected
    })
    e.preventDefault()
  }

  onDropDownMouseOver = e => {
    // special case for no results
    if (this.state.selectedIndex === NO_RESULTS) return
    // event target is the <a>, we want the li
    var target = e.target.parentNode
    var ix = Array.prototype.indexOf.call(target.parentNode.children, target)
    this.setState({
      selectedIndex: ix
    })
  }

  onDropDownClick = e => {
    this.selectSuggestion(this.state.selectedIndex)
    if (!this.state.suggestions[this.state.selectedIndex].url) {
      e.preventDefault()
    }
  }

  onDropDownKeyDown = e => {
    if (this.state.selectedIndex === NO_RESULTS) { return }
    if (e.keyCode === 13) {
      // event target is the <a>, we want the li
      var target = e.target.parentNode
      var ix = Array.prototype.indexOf.call(target.parentNode.children, target)
      this.setState({
        selectedIndex: ix
      })
      this.selectSuggestion(ix)
      e.preventDefault()
    }
  }

  onSubmitClick = e => {
    if (this.props.onSubmit && typeof this.props.onSubmit === 'function') {
      this.props.onSubmit()
    }
  }

  selectSuggestion (ix) {
    if (ix === NO_RESULTS) { return }
    var selected = this.state.suggestions[ix]
    if (selected.index === NO_RESULTS) { return }
    this.setState({
      selectedText: selected.text,
      value: selected.text,
      suggestions: []
    })
    this.props.onChange && this.props.onChange(selected.text)
  }

  findSuggestions () {
    if (!this.state.value) { return false }
    this.setState({
      suggestions: []
    })
    this.props.dataSource(this.state.value, this.onSuggestionsReceived)
  }

  render () {
    var suggestions = this.state.suggestions.map((suggestion, ix) => {
      var selectedClass = (ix === this.state.selected ? 'active' : null)
      return (
        <li key={ix} className={selectedClass}>
          <a
            onMouseOver={this.onDropDownMouseOver}
            onClick={this.onDropDownClick}
            onKeyDown={this.onDropDownKeyDown}
            href={suggestion.url ? suggestion.url : '#'}
            tabIndex={0}
          >{suggestion.text}</a>
        </li>
      )
    })

    var value = this.state.value
    var buttonDisabled = this.state.selectedText === null || this.props.disabled

    var buttonClass = classNames('v2wb-btn', this.props.buttonClass)

    let inputClass = this.props.inputClass
    let blockClass = this.props.blockClass
    let label = null
    let icon = null
    let iconClass = null
    let button = null

    if (this.props.label) {
      if (this.props.labelIcon) {
        iconClass = 'icon ' + this.props.labelIcon
        icon = <i className={iconClass} />
      }
      label = (
        <label>
          {icon} &nbsp;
          {this.props.label} &nbsp;
        </label>
      )
    }

    if (this.props.buttonText || this.props.buttonIcon || this.props.buttonClass) {
      if (this.props.buttonIcon) {
        iconClass = classNames('icon', this.props.buttonIcon)
        icon = <i className={iconClass} />
      }

      button = (
        <button
          className={buttonClass}
          onClick={this.onSubmitClick}
          disabled={buttonDisabled}
        >
          {icon}&nbsp;
          {this.props.buttonText}
        </button>
      )
    }

    // Clear button
    const onClearSearch = e => {
      this.setState({
        selectedText: null,
        value: '',
        suggestions: []
      })
      clearTimeout(this.typeTimeout)
    }

    let clearButton = ''
    if (this.props.showClearButton === 'true') {
      clearButton = (
        <button type="button" className="a-text-field__icon-close" onClick={onClearSearch}>
            <i className="a-icon ui-ic-close-small" title="Clear search"></i>
        </button>
      )
    }

    return (
      <div>
        {label}
        <div className={classNames(blockClass, {
          'open': suggestions.length
        })}>
          <input
            id={this.props.id}
            disabled={this.props.disabled}
            name={this.props.inputName}
            className={inputClass}
            type="text"
            autoComplete="off"
            placeholder={this.props.placeholder}
            value={value}
            onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            aria-controls=""
            {...(this.props.uat ? {'data-uat': this.props.uat} : {})}
          />
          {button}
          {clearButton}
          {this.props.children}
          <ul
            id=""
            className="dropdown-menu"
            style={{ minWidth: '100%' }}
            aria-live="polite"
            aria-atomic="true"
          >
            {suggestions}
          </ul>
        </div>
      </div>
    )
  }
}
