import React from 'react'
import { get } from 'lodash'

import CapturePlusInput from '../../shared/components/CapturePlusInput'

export default class FaiAddressSearch extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      address: {},
      disabled: true,
      value: ''
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ disabled: true })

    window.jQuery.ajax({
      type: 'POST',
      url: '/api/fai-address',
      data: {
        'address': this.state.address,
        'path': window.location.pathname
      },
      dataType: 'json',
      complete: () => {
        let additionalParams = ''
        if (this.props.additionalParams) {
          additionalParams = `&${this.props.additionalParams}`
        }

        let address = this.state.address
        // prepare address for appending to GET query
        let miniObjParameters = this.removeEmpty(address)
        let strParameters = this.params(miniObjParameters)

        // If a function is provided, stay on the current page and call it, otherwise redirect to FAI SERPs
        if (this.props.submitFunction) {
          const functionFromWindowObject = get(window, this.props.submitFunction)
          functionFromWindowObject
            ? functionFromWindowObject(address)
            : this.props.submitFunction({ address, strParameters, additionalParams })
        } else {
          window.location.href = `${this.props.url}?${strParameters}${additionalParams}`
        }
      }
    })

    if (this.props.enableFaiTracking) {
      window.pushDataLayer({
        event: 'contactAnInstaller',
        action: 'Search',
        label: this.state.address.pcaId,
      })
      fbq('track', 'FAISearch')
    }
  }

  params = (data) => {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&')
  }

  removeEmpty = (obj) => {
    return Object.keys(obj).filter(key => obj[key]).reduce(
      (newObj, key) => {
        newObj[key] = obj[key]
        return newObj
      }, {}
    )
  }

  handleChange () {
    if (this.state.address !== {}) {
      this.setState({ disabled: true, value: '' })
    }
  }

  onAddressLookup (address) {
    const disabled = !address
    this.setState({
      address: address,
      disabled: disabled
    })
  }

  render () {
    let disabled = this.state && this.state.disabled
    let formClass = this.props.formClass
    let btnClass = this.props.btnClass
    let btnText = this.props.btnText

    return (
      <form className={(formClass !== undefined) ? 'fai-search ' + formClass : 'fai-search'}>
        <CapturePlusInput
          onChange={address => this.onAddressLookup(address)}
        />
        <button
          className={(btnClass !== undefined) ? 'a-text-field__icon-search ' : 'a-text-field__icon-search'}
          type="button"
          onClick={e => this.handleSubmit(e)}
          disabled={disabled ? 'disabled' : undefined}
        >
          {(btnText !== undefined) ? btnText : <i className="a-icon ui-ic-search" title="Perform search"></i>}
        </button>
      </form>
    )
  }
}
