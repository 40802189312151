import React, { Fragment, useState } from 'react'
import { addressToArray } from '../../../../shared/util/addressToString'
import CapturePlusWithFallback from '../../../../shared/components/CapturePlusWithFallback'

function AddressWithRegistered ({ name, onChange, value, options, editable, error }) {
  const { isUsingRegistered, registeredAddressId } = options
  const [usingRegistered, setUsingRegistered] = useState(isUsingRegistered)
  const [address, setAddress] = useState(value)

  if (!editable) {
    if (usingRegistered) return 'Using registered address'
    return addressToArray(value).map((string, ix) => (
      <Fragment key={ix}>
        {string}<br />
      </Fragment>
    ))
  }

  const handleChangeRegistered = (e) => {
    const registeredValue = e.target.checked
    setUsingRegistered(registeredValue)

    if (registeredValue === true) {
      // the new address is just the id
      onChange(name, registeredAddressId)
    } else {
      // back to the last known address value
      onChange(name, address)
    }
  }

  // we update both the outer state and our inner copy in case the user unticks the checkox and we need to restore the original address
  const handleChangeAddress = updatedAddress => {
    setAddress(updatedAddress)
    onChange(name, updatedAddress)
  }

  return (
    <Fragment>
      <div className='input__container--checkbox margin__bottom--double'>
        <input type='checkbox' checked={usingRegistered} onChange={handleChangeRegistered} className='input__control' />
        <label className='input__label'>Use registered address{' '}</label>
      </div>
      {!usingRegistered && (
        <CapturePlusWithFallback address={address} onChange={handleChangeAddress} />
      )}
    </Fragment>
  )
}

export default AddressWithRegistered
