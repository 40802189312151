import Yayson from 'yayson'

const yayson = Yayson()

const parseJsonApi = (data) => {
  const store = new yayson.Store()
  const { links, meta } = data
  const parsed = store.sync(data)
  if (links || meta) {
    return {
      data: parsed,
      links,
      meta
    }
  }
  return parsed
}

export default parseJsonApi
