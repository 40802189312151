import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class FrokBtnLoader extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    altColor: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string,
  }

  static defaultProps = {
    loading: false,
    disabled: false,
    altColor: false,
    onClick: () => {},
    uat: false, // User Acceptance Testing data attribute
  }

  onClick = () => {
    const { disabled, loading, onClick } = this.props
    if (disabled || loading) return null

    onClick()
  }

  render () {
    const { altColor, children, className, defaultClassName, disabled, loading, style, testId, title, uat } = this.props
    return (
      <button
        type={this.props.type}
        className={classnames(defaultClassName ?? 'btn-loader _no-margin', className, {
          'btn-loader-loading': loading,
          'btn-loader-alt': altColor,
        })}
        title={title}
        disabled={disabled || loading}
        onClick={this.onClick}
        style={style}
        data-test-id={testId}
        {...(uat ? { 'data-uat': uat } : {})}
      >
        <span className='a-button__label'>{children}</span>
        <div className='btn-loader-spinner'>
          <span className='loader' />
        </div>
      </button>
    )
  }
}
