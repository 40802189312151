import React from 'react'
import classNames from 'classnames'

function Toggle ({ name, onChange, value, editable, error }) {
  const tick = '/icons/tick.svg'
  const cross = '/icons/cross.svg'
  return (
    <span onClick={() => onChange(name, !value)} className={classNames(editable && 'editable', error && 'error')}>
      <img className='toggleImg' src={value ? tick : cross} />
      {value ? 'Yes' : 'No'}
    </span>
  )
}

export default Toggle
