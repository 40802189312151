import React from 'react'
import cx from 'classnames'

function TextArea ({ name, onChange, value, editable, error }) {
  if (!editable) return value
  return (
    <div className={cx(error && 'has-error', 'input__container--text')}>
      <textarea
        rows='4'
        cols='50'
        className={'input__control'}
        onChange={e => onChange(name, e.target.value)}
        value={value === null ? '' : value}
      />
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default TextArea
