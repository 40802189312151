import React from 'react'

export default class InfoIcon extends React.Component {
  render () {
    return (
      <a
        role="button"
        href="#"
        className="icon-btn"
        onClick={(ev) => {
          ev.preventDefault()
          this.props.onClick()
        }}
        aria-label="Help"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 40" style={{ width: '24px', height: '24px', fill: 'currentColor', ...this.props.style }}>
          <path d="M26.13,14.88a2.08,2.08,0,0,1-2.14,2A2,2,0,1,1,26.13,14.88Zm-4,3.68h3.68V31.14H22.16V18.56Z" transform="translate(-7.5 -4)"/>
          <path d="M24,6a14.5,14.5,0,0,1,4.67,28.23,2,2,0,0,0-1,.78L24,40.42,20.35,35a2,2,0,0,0-1-.78A14.5,14.5,0,0,1,24,6m0-2a16.5,16.5,0,0,0-5.3,32.13L24,44l5.31-7.87A16.5,16.5,0,0,0,24,4h0Z" transform="translate(-7.5 -4)"/>
        </svg>
      </a>
    )
  }
}

InfoIcon.defaultProps = {
  style: {}
}
