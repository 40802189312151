import Presenter from './Presenter'
import removeNullProperties from '../../util/removeNullProperties'

export class EnquiryPresenter extends Presenter {}

EnquiryPresenter.prototype.type = 'installation-enquiry'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(EnquiryPresenter, Presenter)

export default data => EnquiryPresenter.render(removeNullProperties(data))
