import React, { Fragment, useState } from 'react'
import { addressToArray } from '../../../../shared/util/addressToString'
import CapturePlusWithFallback from '../../../../shared/components/CapturePlusWithFallback'

function Address ({ name, onChange, value, editable, error }) {
  const [address, setAddress] = useState(value)

  if (!editable) {
    return addressToArray(value).map((string, ix) => (
      <Fragment key={ix}>
        {string}<br />
      </Fragment>
    ))
  }

  const handleChangeAddress = updatedAddress => {
    setAddress(updatedAddress)
    onChange(name, updatedAddress)
  }

  return (
      <CapturePlusWithFallback address={address} onChange={handleChangeAddress} />
  )
}

export default Address
