import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ErpRating from './ErpRating'

export default class ErpRatingGroup extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'sm']),
    // ratings: PropTypes.objectOf(PropTypes.shape({
    //   label: PropTypes.string,
    //   ratingClass: PropTypes.string,
    //   score: PropTypes.number
    // })),
    productNumber: PropTypes.string,
    showDefaultLabel: PropTypes.bool
  }

  static defaultProps = {
    ratings: {},
    showDefaultLabel: false
  }

  renderNoRatings () {
    const { showDefaultLabel } = this.props
    if (!showDefaultLabel) return null
    return (
      <span className='erp-group'>
        <div className='erp-tag'>
          N/A
        </div>
      </span>
    )
  }

  render () {
    const { size, ratings, productNumber, className } = this.props

    if (ratings === null) return this.renderNoRatings()

    let items = Object.keys(ratings).filter(type => ratings[type]) // filter out null types

    // Render nowt if no erp labels in the data
    if (items.length === 0) return this.renderNoRatings()

    items = items
      .map(ratingType => {
        const { title, iconType, text } = parseRatingType(ratingType, size)

        if (ratingType === 'productNumber') return null

        const ratingGroupBody = (
          <span>
            <ErpRating
              ratingClass={ratings[ratingType].ratingClass}
              label={ratings[ratingType].label}
              size={size}
            />
            {' '}
            <span
              className={classNames('erp-icon', `erp-icon-${iconType}`, {
                [`erp-icon-${size}`]: size
              })}
              title={title}
            />
            {' '}
            {text}
          </span>
        )

        const ratingGroupAttrs = {
          'className': 'erp-group',
          'data-product': productNumber ?? ratings.productNumber ?? null
        }

        if (typeof this.props.dontLink === 'undefined') {
          ratingGroupAttrs['data-erp-label-modal'] = true
        }

        return (
          <span key={ratingType}>
            {(typeof this.props.dontLink === 'undefined')
            ? <a {...ratingGroupAttrs} href="#">
                {ratingGroupBody}
              </a>
            : <span {...ratingGroupAttrs}>
              {ratingGroupBody}
            </span>
            }
          </span>
        )
      })

    return (
      <div className={className}>
        {items}
      </div>
    )
  }
}

function parseRatingType (ratingType, size) {
  let iconType = ''
  let title = ''
  let text = ''
  if (ratingType === 'heatingRating' || ratingType === 'heating55Rating' || ratingType === 'heating35Rating') {
    title = 'Space Heating'
    iconType = 'radiator'
  }
  if (ratingType === 'heating55Rating') {
    text = <span className={'erp-text erp-text-' + size }>55&deg;C</span>
  }
  if (ratingType === 'heating35Rating') {
    text = <span className={'erp-text erp-text-' + size }>35&deg;C</span>
  }
  if (ratingType === 'waterHeatingRating') {
    title = 'Water Heating'
    iconType = 'tap'
  }
  if (ratingType === 'seerRating') {
    title = 'SEER (Seasonal Energy Efficiency Ratio)'
    iconType = 'seer'
  }
  if (ratingType === 'scopRating') {
    title = 'SCOP (Seasonal Coefficient of Performance)'
    iconType = 'scop'
  }
  if (ratingType === 'cylinderRating') {
    title = 'Cylinder efficiency'
    iconType = 'cylinder'
  }
  if (ratingType === 'upliftRating') {
    title = 'Uplift rating'
    iconType = 'control'
  }
  return { title, iconType, text }
}
