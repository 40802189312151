import presenterFactory from 'yayson'

/**
 * Base presenter to help convert from our application objects
 * to JSON API suitable resources.
 *
 * Usage:
 * Extend what is exported from this file, then export the render function
 * of your new class. You should also export the class itself as a named
 * export as it might be used as a relationship presenter in other presenters.
 *
 * e.g.
 * export class TestPresenter extends Presenter {}
 *
 * TestPresenter.prototype.type = 'test'
 *
 * export default data => TestPresenter.render(data)
 *
 * For details on how to custom the attributes / id look at the yayson docs:
 * https://github.com/confetti/yayson/blob/master/README.md
 */
export default presenterFactory().Presenter
