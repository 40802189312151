import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import chunkArray from '../../shared/util/chunkArray.js'
import numberToWord from '../util/numberToWord.js'

export default class Grid extends React.Component {

  static propTypes = {
    bias: PropTypes.oneOf(['right', 'left']),
    cols: PropTypes.oneOf([2, 3, 4]),
    flex: PropTypes.bool,
    swap: PropTypes.bool
  }

  static defaultProps = {
    cols: 2,
    flex: false,
    swap: false
  }

  render () {
    let { bias, children } = this.props
    const { cols, flex, swap } = this.props
    children = React.Children.toArray(this.props.children)

    if (this.props.swap) {
      bias = (bias === 'right') ? 'left' : 'right'
      children = children.reverse()
    }

    const containerClasses = classnames(
      'grid',
      'grid-' + numberToWord(this.props.cols) + '-col',
      {
        ['grid-col-' + bias]: !!bias,
        'grid-swap': swap,
        'grid-flex': flex
      }
    )

    const rows = chunkArray(children, cols)

    return (
      <div>
        {rows.map((children, index) => (
          <div key={index} className={containerClasses}>
            {children.map((child, index) => (
              <div key={index} className={'grid-col'}>{child}</div>
            ))}
          </div>
        ))}
      </div>
    )
  }
}
