import React, { useState, useCallback } from 'react'
import LandingPageLink from './LandingPageLink'
import classNames from 'classnames'

/**
 *
 * @typedef {{
 * image?: { src: string, alt: string },
 * alternativeImage?: { src: string, alt: string },
 * heading?: string,
 * content?: { text?: string, isItalic?: boolean }[],
 * extraInfo?: { heading?: string, paragraph?: string }[],
 * cta?: { text?: string, href?: string, onClick?: Function  }[],
 * }} ContentRow
 *
 * @param { {contentRows?: ContentRow[]} } props
 */
const ContentRows = ({ contentRows, defaultSectionSpacing = true }) => {
  if (!contentRows || contentRows.length === 0) return null

  const classes = classNames('landing-page-content-rows m-banner__background', defaultSectionSpacing && 'default-section-spacing')

  return (
    <section className={classes}>
      {contentRows.map((contentRow, index) => (
        <Row {...contentRow} key={index} isReversed={index % 2} rowIndex={index} />
      ))}
    </section>
  )
}

/**
 * @param { ContentRow } props
 */
const Row = ({ isReversed, image, heading, content, extraInfo, cta, alternativeImage, rowIndex }) => {
  const [activeExtraInfo, setActiveExtraInfo] = useState(0)

  const isAnimated = !!image?.src && !!alternativeImage?.src

  const imageContainerClass = useCallback(
    active => `landing-page-content-rows__image-container${isAnimated ? ' animated' : ''}${active ? ' active' : ''}`,
    [isAnimated],
  )

  return (
    <div className={`landing-page-content-rows__row${isReversed ? ' landing-page-content-rows__row--reversed' : ''}`}>
      <div className={imageContainerClass(activeExtraInfo === 0)}>
        {image?.src && <img className='landing-page-content-rows__image' src={image.src} alt={image?.alt} />}
      </div>

      {alternativeImage?.src && (
        <div
          className={`landing-page-content-rows__image-container--alternative ${imageContainerClass(
            activeExtraInfo === 1,
          )}`}
        >
          <img
            className='landing-page-content-rows__image landing-page-content-rows__image--alternative'
            src={alternativeImage.src}
            alt={alternativeImage?.alt}
          />
        </div>
      )}

      <div className='e-container'>
        <div className='landing-page-content-rows__wrapper'>
          {heading && <h2 className='landing-page-content-rows__heading -size-3xl'>{heading}</h2>}

          {content &&
            content.length > 0 &&
            content.map((paragraph, index) => (
              <p
                key={index}
                className={[
                  'landing-page-content-rows__paragraph paragraph-medium-desktop',
                  paragraph.isItalic && 'landing-page-content-rows__paragraph--italic',
                ]
                  .join(' ')
                  .trim()}
              >
                {paragraph.text}
              </p>
            ))}

          {extraInfo &&
            extraInfo.length > 0 &&
            extraInfo.map((info, index) => (
              <div
                key={index}
                role='tablist'
                className={`landing-page-content-rows__extra-info landing-page-content-rows__left-line${
                  index === activeExtraInfo ? ' active' : ''
                }`}
              >
                {info.heading && (
                  <h3
                    id={`content-rows-tab-${rowIndex}-${index}`}
                    className='landing-page-content-rows__extra-info-heading -size-xl'
                    onClick={() => setActiveExtraInfo(index)}
                    aria-controls={`content-rows-panel-${rowIndex}-${index}`}
                    aria-selected={activeExtraInfo === index}
                    tabIndex={-1}
                    type='button'
                    role='tab'
                  >
                    {info.heading}
                  </h3>
                )}
                {info.paragraph && (
                  <p
                    id={`content-rows-panel-${rowIndex}-${index}`}
                    className='landing-page-content-rows__extra-info-paragraph'
                    hidden={activeExtraInfo !== index}
                    aria-labelledby={`content-rows-tab-${rowIndex}-${index}`}
                    tabIndex={0}
                    role='tabpanel'
                  >
                    {info.paragraph}
                  </p>
                )}
              </div>
            ))}

          {cta?.href && cta.text && <LandingPageLink cta={cta} />}
        </div>
      </div>
    </div>
  )
}

export default ContentRows
