import React, { useState } from 'react'
import loginBenchmarkAccount from '../../network/requests/loginBenchmarkAccount'
import logBenchmarkOnboarding from '../../network/requests/logBenchmarkOnboarding'
import { CONTENT_STATE } from './index'
import InputText from '../InputText'

const ConnectNow = props => {
  const { setNextContent, closeModal, onConnect } = props
  const [formState, setFormState] = useState({ email: '', password: '' })
  const [formErrors, setFormErrors] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const submitConnectAccounts = async () => {
    if (loading) return
    setError('')
    setFormErrors({})
    const err = {}
    if (!formState.email) err.email = 'Required field'
    if (!formState.password) err.password = 'Required field'

    if (Object.keys(err).length) {
      setFormErrors(err)
      return
    }
    setLoading(true)
    loginBenchmarkAccount(formState)
      .then(() => {
        onConnect()
        setNextContent(CONTENT_STATE.ACCOUNTS_CONNECTED)
      })
      .catch(remoteError => {
        const defaultError = 'An error has occured, please try again'
        setError(remoteError?.responseJSON?.errors?.detail ?? defaultError)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='onboarding__content--installer'>
      <h3 className='onboarding__title--installer -size-xl m-t-0'>Connect your accounts</h3>
      <p className='onboarding__text--installer'>
        To connect your accounts please sign in to your Benchmark Checklist account below. Please note, we do not
        <br />
        share any of your personal data from your loyalty account with Benchmark.
      </p>
      <div className='onboarding__inputs'>
        {/* TODO_HHIC: Styling for input username/password */}
        <InputText
          label='Email address/username'
          placeholder='john.smith@example.com'
          value={formState.email}
          onChange={e => setFormState({ ...formState, email: e.target.value })}
          error={formErrors.email}
        />
        <InputText
          label='Password'
          type='password'
          placeholder='•••••••'
          value={formState.password}
          onChange={e => setFormState({ ...formState, password: e.target.value })}
          error={formErrors.password}
        />
      </div>
      <div className="onboarding__buttons--top">
        <div className="a-link a-link--button onboarding__button--connect">
          <a
            style={{ border: 'none' }}
            onClick={() => {
              logBenchmarkOnboarding()
              closeModal()
            }}>
            <span>Connect later</span>
          </a>
        </div>
        <br/>
        <div className="a-link a-link--button-secondary onboarding__button--connect">
          <a onClick={submitConnectAccounts}>
            <span>Connect accounts</span>
          </a>
        </div>
      </div>
      {error &&
      (<div className="a-notification a-notification--text -error" role="alert">
        <i className="a-icon ui-ic-alert-error"></i>
        <div id="text-field-5-label" className="a-notification__content">
          {error}
        </div>
      </div>)
      }
    </div>
  )
}

export default ConnectNow
