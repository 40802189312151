import React from 'react'
import PropTypes from 'prop-types'
import FileInput from './FileInput'

export default class FilePicker extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    maxSize: PropTypes.number,
    required: PropTypes.bool,
    name: PropTypes.string,
    extensions: PropTypes.array,
    validateContent: PropTypes.func,
    style: PropTypes.object,
    preview: PropTypes.bool,
  }

  static defaultProps = {
    maxSize: 5,
    required: false,
    name: '',
  }

  validate = file => {
    const { onError, onChange, maxSize, extensions } = this.props

    if (!file) {
      onError('Failed to upload a file.')
      return
    }

    if (extensions) {
      const uploadedFileExt = file.name
        .split('.')
        .pop()
        .toLowerCase()
      const isValidFileExt = extensions.map(ext => ext.toLowerCase()).includes(uploadedFileExt)

      if (!isValidFileExt) {
        onError(`Must upload a file of type: ${extensions.join(' or ')}`)
        return
      }
    }

    // convert maxSize from megabytes to bytes
    const maxBytes = maxSize * 1000000

    if (file.size > maxBytes) {
      onError(`File size must be less than ${maxSize} MB.`)
      return
    }

    onChange(file)
  }

  render () {
    const { children, style, required, name, preview } = this.props

    return (
      <FileInput preview={preview} onChange={this.validate} name={name} style={style} required={required}>
        {children}
      </FileInput>
    )
  }
}
