import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export default class FrokFormGroup extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    displayLabelAsTitle: PropTypes.bool,
    isFastTrackForm: PropTypes.bool,
  }

  static get defaultProps () {
    return {
      required: false,
      error: undefined,
      label: null,
      isFastTrackForm: false,
    }
  }

  renderLabel () {
    if (!this.props.label || this.props.label.length === 0) return
    return (
      <div>
        <label htmlFor={this.props.name} className={`control-label`}>
          {this.props.displayLabelAsTitle ? (
            <h3 className="-size-xl">
              {this.props.label}
              {this.props.required && '*'}
              {this.props.isFastTrackForm && !this.props.required && <span> (optional)</span>}
            </h3>
          ) : (
            <>
              {this.props.label}
              {this.props.required && '*'}
              {this.props.isFastTrackForm && !this.props.required && <span> (optional)</span>}
            </>
          )}
        </label>
      </div>
    )
  }

  renderError () {
    const { error, isFastTrackForm } = this.props
    if (error === undefined || error === '') return null
    return (
      <div className="a-notification a-notification--text -error" role="alert">
        {isFastTrackForm && <i className="a-icon ui-ic-alert-error"></i>}
        <div className="a-notification__content">
            {error}
        </div>
      </div>
    )
  }

  renderFormField () {
    const { size, children, isFastTrackForm } = this.props
    return (
    <div className={`m-form-field ${size ? `m-form-field--${size}` : ''}`}>
      {this.renderLabel()}
      {isFastTrackForm ? <div className='fast-track-form__input-wrapper'>{children}</div> : children}
      {this.renderError()}
    </div>
    )
  }

  render () {
    const { required, error, disabled } = this.props
    const containerClasses = classnames({
      'is-required': required,
      'has-error': error !== undefined,
      disabled,
    })
    return (
      <div className={containerClasses}>
        {this.renderFormField()}
      </div>
    )
  }
}
