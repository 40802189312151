export const ajax = (url, data, cancelToken = {}) => {
  return new Promise((resolve, reject) => {
    let match = document.cookie.match(new RegExp('(^|;\\s*)(XSRF-TOKEN)=([^;]*)'))
    window.$.ajaxSetup({
      headers: {
        'X-XSRF-TOKEN': (match ? decodeURIComponent(match[3]) : null)
      }
    })
    const xhrRequest = window.$.ajax(url, data)
    if (xhrRequest.done) {
      xhrRequest.done((data, textStatus, xhr) => {
        delete cancelToken.cancel
        resolve(data instanceof Array ? data : { ...data, statusCode: xhr.status })
      })
      xhrRequest.fail(data => {
        delete cancelToken.cancel
        reject(data)
      })
    } else {
      xhrRequest.then((data, textStatus, xhr) => {
        delete cancelToken.cancel
        resolve(data instanceof Array ? data : { ...data, statusCode: xhr.status })
      })
      xhrRequest.catch(data => {
        delete cancelToken.cancel
        reject(data)
      })
    }

    cancelToken.cancel = () => {
      xhrRequest.abort()
      reject(new Error('Request cancelled'))
    }
  })
}

// configure api
const beforeSend = (xhr, options) => {
  const baseUrl = '/api'
  // Only sanitise the URL if its not absolute
  if (!absoluteUrl(options.url)) options.url = baseUrl + sanitiseUrl(options.url)

  if (options.contentType !== false) {
    xhr.setRequestHeader('Content-Type', 'application/vnd.api+json')
  }

  xhr.setRequestHeader('Accept', 'application/vnd.api+json')
}

const delay = (time, cancelToken = {}) => {
  let _timeout
  return result =>
    new Promise((resolve, reject) => {
      _timeout = setTimeout(() => resolve(result), time)
      cancelToken.cancel = () => {
        clearTimeout(_timeout)
        const error = new Error('promise cancelled')
        error.__CANCEL__ = true
        reject(error)
      }
    })
}

/**
 * Returns true if the url is absolute, otherwise it's probably
 * a relative URL, and will return false.
 * @param {String} url The url to test
 */
export const absoluteUrl = url => /^https?:\/\//.test(url)

/**
 * Make sure the requested url starts with a slash and contains a version
 * If it already contains a version, it uses that, otherwise assumes v3
 * e.g. `/v3.1/resource` will be untouched, `/resource` will return `/v3/resource` and 'thing' will become '/thing'
 * @param url
 * @returns {string}
 */
export function sanitiseUrl (url) {
  if (!url.startsWith('/')) url = `/${url}`
  if (/^\/v\d+(.\d+)?\//.test(url) === false) url = `/v3${url}`
  return url
}

function getHeaders () {
  let match = document.cookie.match(new RegExp('(^|;\\s*)(XSRF-TOKEN)=([^;]*)'))
  return {
    'X-XSRF-TOKEN': (match ? decodeURIComponent(match[3]) : null),
    'Accept': 'application/vnd.api+json'
  }
}

export default {
  get: (url, data, token) => {
    return ajax(
      url,
      {
        beforeSend,
        method: 'GET',
        data,
      },
      token,
    )
  },
  fetch: (url, data, token) => {
    return fetch(url, {
      headers: getHeaders()
    })
  },
  post: (url, data, token) => {
    return ajax(
      url,
      {
        contentType: 'application/json',
        beforeSend,
        method: 'POST',
        data: JSON.stringify(data),
      },
      token,
    )
  },
  patch: (url, data, token) => {
    return ajax(
      url,
      {
        contentType: 'application/json',
        beforeSend,
        method: 'PATCH',
        data: JSON.stringify(data),
      },
      token,
    )
  },
  delete: (url, data, token) => {
    return ajax(
      url,
      {
        contentType: 'application/json',
        beforeSend,
        method: 'DELETE',
        data: JSON.stringify(data),
      },
      token,
    )
  },
  file: (url, file, token) => {
    const formData = new FormData()
    formData.append('file', file)
    return ajax(
      url,
      {
        contentType: false,
        processData: false,
        beforeSend,
        cache: false,
        method: 'POST',
        data: formData,
      },
      token,
    )
  },
  // for faking api calls during development
  echo: (response, wait, token) => {
    return Promise.resolve(response).then(delay(wait, token))
  },
}
