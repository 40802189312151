import React, {useState} from 'react'

const Notification = ({ type, text, icon, dismissible, classnames }) => {
  const [dismissed, setDismissed] = useState(false)

  if (dismissed) return null

  return (
    <div className={`a-notification -${type} ${dismissible && '-dismissible'} ${classnames}`} role="alert">
      <i className={`a-icon ${icon}`} title={type}></i>
      <div className="a-notification__content">
        {text}
      </div>
      {!dismissed && dismissible &&
        (<button type="button" onClick={() => setDismissed(true)} className="a-button a-button--integrated -without-label" data-frok-action="close" aria-label="Close banner">
          <i className="a-icon a-button__icon ui-ic-close"></i>
        </button>)
      }
    </div>
  )
}

export default Notification
