import React from 'react'

/**
 * The ie-fix key is to make sure react doesn't try and re-use this dom node
 * which causes a bug with css animation
 * https://github.com/FortAwesome/Font-Awesome/issues/7572
 */
export default function Loader ({ text }) {
  return (
    <div className="text-center">
      <div>{text}</div>
      <div key='ie-fix' className="loader" />
    </div>
  )
}
