import React from 'react'

export default class FrokModal extends React.Component {

  dialogRef = React.createRef();

  closeDialog = () => {
    this.dialogRef.current.close()
  }

  render () {

    var dialogTitleId = `dialog-${this.props.id}-title`
    const dialogDescriptionId = `dialog-${this.props.id}-description`

    return (
      <>
        <dialog className="m-dialog -floating-shadow-s"
          id={this.props.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={this.props.id + 'Label'}
          aria-hidden="true" ref={this.dialogRef}
        >

          <div className="m-dialog__header">
            {this.props.title && <div className="m-dialog__title">{this.props.title}</div>}
            {this.props.closeButton &&
              (<button type="button"
                className="a-button a-button--integrated -without-label"
                onClick={this.closeDialog}
                data-frok-action="close"
                aria-label="close dialog">
                  <i className="a-icon a-button__icon ui-ic-close" title="Close modal"></i>
              </button>)
            }
          </div>
          <hr className="a-divider" />

          {this.props.headline && (
            <div className="m-dialog__content">
              <div className="m-dialog__headline" id={dialogTitleId}>
                {this.props.headline}
              </div>
            </div>
          )}
          <div className="m-dialog__body" id={dialogDescriptionId}>
            {this.props.children}
          </div>

          {/* {dialogCode && <div className="m-dialog__code">{dialogCode}</div>} */}

          <div className="m-dialog__actions">
            {this.props.optionalButton && (
              <button
                type="button"
                className="a-button a-button--secondary -without-icon"
                data-frok-action="optional"
              >
                <span className="a-button__label">{this.props.optionalButton}</span>
              </button>
            )}

            {this.props.primaryButton && (
              <button
                type="button"
                className="a-button a-button--primary -without-icon"
                data-frok-action="confirm"
              >
                <span className="a-button__label">{this.props.primaryButton}</span>
              </button>
            )}

            {this.props.secondaryButton && (
              <button
                type="button"
                className="a-button a-button--secondary -without-icon"
                onClick={this.closeDialog}
                data-frok-action="close"
                aria-label="close dialog"
              >
                <span className="a-button__label">{this.props.secondaryButton}</span>
              </button>
            )}
          </div>

        </dialog>
        <div className="m-dialog__backdrop" onClick={this.closeDialog} />
      </>
    )
  }
}

FrokModal.defaultProps = {
  closeButton: true
}

