import React, { useState, createContext } from 'react'

const ROUTER_TYPES = {
  REACT_ROUTER: 'react-router',
  REACT_ROUTER_DOM: 'react-router-dom',
}

const LandingPageContext = createContext()

const LandingPageProvider = ({ children }) => {
  const [routerType, setRouterType] = useState()
  const [routes, setRoutes] = useState()

  return (
    <LandingPageContext.Provider value={{ routerType, setRouterType, routes, setRoutes }}>
      {children}
    </LandingPageContext.Provider>
  )
}
export { LandingPageProvider, LandingPageContext, ROUTER_TYPES }
