import React from 'react'

export default class Modal extends React.Component {
  render () {
    var btnClass = 'btn'
    if (this.props.buttons) {
      btnClass += ' btn-default'
    } else {
      btnClass += ' btn-primary'
    }

    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby={this.props.id + 'Label'} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content box">
            <div className={(this.props.noBody) ? '' : 'modal-body'}>
              <h3 id={this.props.id + 'Label'}>{this.props.title}</h3>
              {this.props.children}
            </div>
            <div className="modal-footer btn-row">
              <button type="button" className={btnClass} data-dismiss="modal">Close</button>
              {this.props.buttons}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
