import React from 'react'
import classNames from 'classnames'
import { formatMonth } from '../utils/formatDate'

export default function DayPickerNavBar ({ showPreviousButton, showNextButton, onPreviousClick, onNextClick, month, iconClasses }) {
  return (
    <div className='DayPicker-Nav'>
      <div
        className={classNames('DayPicker-NavButton', showPreviousButton && 'show')}
        onClick={() => onPreviousClick()}
      >
        <i className={iconClasses ?? 'icon icon-chevron-left'}></i>
      </div>
      <h3>{formatMonth(month)}</h3>
      <div className={classNames('DayPicker-NavButton', showNextButton && 'show')} onClick={() => onNextClick()}>
        <i className={iconClasses ?? 'icon icon-chevron-right'}></i>
      </div>
    </div>
  )
}
