import React, { useEffect } from 'react'

const Trustpilot = ({ heading, tags }) => {
  const trustBoxRef = React.useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [])

  return (
    <section className='landing-page-trustpilot default-section-spacing'>
      <div className='band -secondary landing-page-trustpilot__band landing-page-container'>
        <div className='e-container landing-page-trustpilot__container'>
          {heading && <h2 className='landing-page-trustpilot__heading -size-3xl'>{heading}</h2>}

          <div
            ref={trustBoxRef}
            className='trustpilot-widget landing-page-trustpilot__trustpilot-widget'
            data-locale='en-GB'
            data-template-id='539ad0ffdec7e10e686debd7'
            data-businessunit-id='4be53f1500006400050920ee'
            data-style-height='350px'
            data-style-width='100%'
            data-theme='light'
            data-tags={tags}
            data-stars='5'
            data-review-languages='en'
          >
            <a href='https://www.trustpilot.com/review/www.worcester-bosch.co.uk' target='_blank' rel='noreferrer'>
              Trustpilot
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Trustpilot
