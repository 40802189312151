import React from 'react'
import Select from 'react-select'

function CheckboxCards ({ name, onChange, value, options, editable }) {
  if (!editable) {
    return (
      <div className="details__value--tiles">
        {value.map(id => {
          if (typeof options[id] === 'undefined') return

          return <div key={id} className="quicklinks__container--preferences">
            <img className="quicklinks__icon" src={ options[id].icon }></img>
            <span className="quicklinks__text">{ options[id].name }</span>
          </div>
        })}
      </div>
    )
  }

  return <Select
    name={name}
    defaultValue={value.map(id => {
      if (typeof options[id] === 'undefined') return

      return {value: id, label: options[id].name}
    })}
    isMulti={true}
    onChange={pickedOptions => onChange(name, pickedOptions.map(option => option.value))}
    options={Object.keys(options).map(option => {
      return {value: options[option].id, label: options[option].name}
    })}
  />
}

export default CheckboxCards
