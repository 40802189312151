import React, { useState } from 'react'
import FrokAddressFields from './FrokAddressFields'
import CapturePlusInput from './CapturePlusInput'

export default function CapturePlusWithFallback ({ address, onChange }) {
  const [showFallback, setShowFallback] = useState(false)

  const handleShowFallback = e => {
    e.preventDefault()
    setShowFallback(true)
  }

  const handleCapturePlusChange = address => {
    if (address === undefined) return
    onChange(address)
  }

  return (
    <div>
      {showFallback === false && (
        <div className='e-flex -column e-flex-gap-xs'>
          <CapturePlusInput onChange={handleCapturePlusChange} />
          <button className="a-button a-button--integrated -without-icon" onClick={handleShowFallback}><span className="a-button__label">Can&apos;t find your address? Enter your address details manually.</span></button>
        </div>
      )}
      {showFallback === true && (
        <FrokAddressFields address={address} onChange={onChange} />
      )}
    </div>
  )
}
