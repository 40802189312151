import React, { useState, Fragment } from 'react'
import AddressFields from './AddressFields'
import CapturePlusInput from './CapturePlusInput'

export default function CapturePlusWithFallback ({ address, onChange }) {
  const [showFallback, setShowFallback] = useState(false)

  const handleShowFallback = e => {
    e.preventDefault()
    setShowFallback(true)
  }

  const handleCapturePlusChange = address => {
    if (address === undefined) return
    onChange(address)
  }

  return (
    <div>
      {showFallback === false && (
        <Fragment>
          <CapturePlusInput
            onChange={handleCapturePlusChange}
          />
          <a onClick={handleShowFallback}>Can&apos;t find your address? Enter your address details manually.</a>
        </Fragment>
      )}
      {showFallback === true && (
        <AddressFields address={address} onChange={onChange} />
      )}
    </div>
  )
}
