import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const FrokFilePicker = ({
  name,
  onChange,
  onError,
  maxSize = 5,
  extensions,
  label = 'Choose file',
  preview,
  compact = false,
}) => {
  const [fileName, setFileName] = useState(null)
  const input = useRef(null)

  const isValidExtension = file => {
    if (!extensions) {
      return true
    }

    const uploadedFileExt = file.name
      .split('.')
      .pop()
      .toLowerCase()

    return extensions.map(ext => ext.toLowerCase()).includes(uploadedFileExt)
  }

  const isValidSize = file => {
    const maxBytes = maxSize * 1000000

    return file.size <= maxBytes
  }

  const validate = file => {
    if (!file) {
      onError('Failed to upload a file.')
      return
    }

    if (!isValidExtension(file)) {
      onError(`File must be one of: ${extensions.join(', ')}`)
      return
    }

    if (!isValidSize(file)) {
      onError(`File size must be less than ${maxSize} MB.`)
      return
    }

    setFileName(file.name)

    onChange(file)
  }

  const handleUpload = event => {
    validate(event.target.files[0])
  }

  const renderButton = () => {
    if (fileName && compact) {
      return null
    }

    return (
      <label htmlFor={name} className={`compact ? '-flex-1' : ''`}>
        <i className="a-icon boschicon-bosch-ic-upload" title="upload icon"></i>
        {label}
      </label>
    )
  }

  const renderLabel = () => {
    if (compact) {
      return null
    }

    return (
      <div className="a-file-upload-input__preview">
        <p>{fileName || 'No file chosen'}</p>
      </div>
    )
  }

  const renderPreview = () => {
    if (!preview) {
      return null
    }

    return (
      <img
        src={preview}
        onClick={() => input.current?.click()}
        style={{ maxWidth: compact ? '100%' : '50%' }}
        alt={label}
      />
    )
  }

  return (
    <>
      <div className="a-file-upload-input">
        {renderButton()}
        <input id={name} name="file upload input" type="file" onChange={handleUpload} ref={input} />
        {renderLabel()}
      </div>
      {renderPreview()}
    </>
  )
}

FrokFilePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  extensions: PropTypes.array,
  label: PropTypes.string,
  preview: PropTypes.string,
  compact: PropTypes.bool,
}

export default FrokFilePicker
