import React from 'react'
import PropTypes from 'prop-types'

export default class FileInput extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    name: PropTypes.string,
    preview: PropTypes.bool,
  }

  static defaultProps = {
    required: false,
    name: '',
  }

  constructor (props) {
    super(props)
    this.state = {
      image: '',
    }
  }

  handleUpload = event => {
    const file = event.target.files[0]
    this.props.onChange(file)
    this.setState({ image: URL.createObjectURL(file) })

    // free up the fileInput again
    this.fileInput.value = null
  }

  render () {
    return (
      <div style={this.props.style}>
        <input
          type='file'
          required={this.props.required}
          name={this.props.name}
          style={{ display: 'none' }}
          onChange={this.handleUpload}
          ref={ele => {
            this.fileInput = ele
          }}
        />
        {React.cloneElement(this.props.children, {
          onClick: () => this.fileInput.click(),
        })}
        {this.props.preview && this.state.image.length > 0 && (
          <img style={{ maxWidth: '300px', display: 'block', padding: '20px 0' }} src={this.state.image} />
        )}
      </div>
    )
  }
}
