import React from 'react'

class Image extends React.PureComponent {
  state = { error: false }

  get classes () {
    const { className } = this.props
    const { error } = this.state
    return [className, error && 'display-none'].filter(Boolean).join(' ')
  }

  handleError = event => {
    if (this.props.onError) this.props.onError(event)
    this.setState({ error: true })
  }

  render () {
    return <img {...this.props} className={this.classes} onError={this.handleError}/>
  }
}

export default Image
