import parseAddress from './parseAddress'

/**
 * Converts the response from PCA into an array of WB addresses
 * @param response
 */
export default response => {
  // Is the response directly already an array?
  if (Array.isArray(response)) return response.map(parseAddress)
  // does it have a PCA ID?
  if (response.Id !== undefined) return parseAddress(response)
  // Otherwise, it looks like a jQuery/Axios request
  // Get the items
  const items = response.Items || (response.data && response.data.Items) || []
  // If an array, then return the items mapped
  if (Array.isArray(items)) return items.map(parseAddress)
  // Otherwise just try and parse the raw object
  return parseAddress(items)
}
