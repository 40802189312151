import React from 'react'

const FrokNotification = ({
  children,
  type = '-neutral',
  icon = 'boschicon-bosch-ic-emoji-happy',
  noIcon = false,
}) => {
  return (
    <div className={`a-notification ${type}`} role="alert">
      {!noIcon && <i className={`a-icon ${icon}`}></i>}
      <div className="a-notification__content">
        {children}
      </div>
    </div>
  )
}

export default FrokNotification
