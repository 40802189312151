import React from 'react'

function Checkbox ({ name, onChange, value, options, editable, error }) {
  if (!editable) {
    return value ? (
      <React.Fragment><span className="icon__guarantee--yes"></span> Yes</React.Fragment>
    ) : (
      <React.Fragment><span className="icon__guarantee--no"></span> No</React.Fragment>
    )
  }

  return (
    <div>
      <div className="input__container--radio">
        <input type="radio" name={name} className="input__control" onChange={() => onChange(name, true)} checked={value} />
        <label className="input__label">Yes</label>
        <span className='input__validation'>{error}</span>
      </div>
      <div className="input__container--radio">
        <input type="radio" name={name} className="input__control" onChange={() => onChange(name, false)} checked={!value} />
        <label className="input__label">No</label>
      </div>
    </div>
  )
}

export default Checkbox
