import Presenter from '../../shared/network/presenters/Presenter'

export class BasketItemPresenter extends Presenter {}

BasketItemPresenter.prototype.type = 'basket-product'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(BasketItemPresenter, Presenter)

export default data => BasketItemPresenter.render(data)
