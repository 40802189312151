import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from './FormGroup'

export default class TextInput extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    inputClass: PropTypes.string,
    outerClass: PropTypes.string,
    maxLength: PropTypes.number,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    isFastTrackForm: PropTypes.bool,
  }

  static defaultProps = {
    error: undefined,
    required: false,
    uat: false, // User Acceptance Testing data attribute
    value: '',
    onChange: () => {},
    isFastTrackForm: false,
  }

  onChange = event => {
    return this.props.onChange(event.target.value)
  }

  render () {
    const { name, value, placeholder, onBlur, uat, maxLength, subLabel } = this.props
    return (
      <FormGroup {...this.props}>
        <input
          type="text"
          className={'form-control'}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value || ''}
          onChange={this.onChange}
          onBlur={onBlur}
          {...(uat ? {'data-uat': uat} : {})}
          maxLength={maxLength}
        />
        { subLabel && subLabel.length > 0 && <small>{ subLabel }</small>}
      </FormGroup>
    )
  }
}
