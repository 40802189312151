import React from 'react'
import PinnedButton from './PinnedButton'
import { trackEvent } from '../analytics'

export default class PinnedButtonGroup extends React.Component {
  static get defaultProps () {
    return {
      liveChat: true
    }
  }

  renderLiveChat () {
    // quickly turn off the live chat button
    if (!this.props.liveChat) return null

    return (
      <PinnedButton
        disabled={true}
        text='Live Chat'
        onClick={() => {
          trackEvent('live-chat', 'open')
          Tawk_API.showWidget()
          Tawk_API.maximize()
        }}
      />
    )
  }

  render () {
    return (
      <div className="pinned-buttons">
        {this.props.children}
        {this.renderLiveChat()}
      </div>
    )
  }
}
