import React from 'react'

import Accordion from '../../../redesign/Accordion'

const Faqs = ({ faqs, title }) => {
  if (!faqs || faqs.length === 0) return null

  return (
    <section className='o-faqs default-section-spacing'>
      <header className='o-faqs__header'>
        <div className='e-container text-center'>{title && <h2 className='o-faqs__title'>{title}</h2>}</div>
      </header>

      <div className='o-faqs__main'>
        <div className='e-container'>
          {faqs &&
            faqs.map((faq, index) => <Accordion key={index} id={index} content={faq.answer} headline={faq.question} />)}
        </div>
      </div>
    </section>
  )
}

export default Faqs
