import productSelector from './productSelector'

export default (state, productNumber) => {
  const { byProductNumber } = state.basket
  const basketItem = byProductNumber[productNumber]
  const product = productSelector(state, productNumber)
  return {
    ...product,
    ...basketItem,
  }
}
