import React from 'react'

const RepairSVG = ({ fill = 'white', width = '86', height = '69' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 86 69'>
    <path
      fill={fill}
      fillRule='nonzero'
      d='M40.052 38.974c6.9-3.288 11.698-10.35 11.698-18.49C51.75 9.164 42.586 0 31.266 0 19.946 0 10.78 9.164 10.78 20.484c0 8.14 4.798 15.202 11.698 18.49C9.54 42.802 0 54.823 0 69h4.313c0-14.878 12.075-26.953 26.953-26.953S58.219 54.122 58.219 69h4.312c0-14.177-9.541-26.198-22.479-30.026zm-24.958-18.49c0-8.894 7.277-16.172 16.172-16.172 8.894 0 16.172 7.278 16.172 16.172 0 8.895-7.278 16.172-16.172 16.172-8.895 0-16.172-7.277-16.172-16.172zm69.161 5.337l-1.293-2.75L72.18 33.854c-.808.809-2.21.809-3.072 0a2.129 2.129 0 0 1-.647-1.51c0-.538.216-1.131.647-1.509l10.781-10.78-2.75-1.295a15.133 15.133 0 0 0-17.195 2.965c-3.504 3.504-5.014 8.517-4.205 13.315l-4.528 4.528a41.665 41.665 0 0 1 3.45 2.642l5.876-5.876-.324-1.186c-.97-3.72.054-7.709 2.803-10.404 2.426-2.426 5.876-3.504 9.164-3.073l-6.09 6.092a6.398 6.398 0 0 0-1.888 4.582c0 1.725.647 3.342 1.887 4.582a6.398 6.398 0 0 0 4.582 1.886 6.398 6.398 0 0 0 4.582-1.886l6.092-6.092c.485 3.289-.593 6.685-3.073 9.164-2.695 2.696-6.684 3.774-10.404 2.804l-1.186-.324-6.2 6.2c.863 1.185 1.618 2.479 2.319 3.773l5.229-5.23c4.851.863 9.81-.7 13.315-4.204 4.474-4.528 5.66-11.428 2.91-17.196z'
    />
  </svg>
)

export default RepairSVG
