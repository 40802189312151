/**
 * return an address object as a formatted string
 * @param address
 * @param delimiter
 * @returns {string}
 */
const addressToString = (address = {}, delimiter = '\n') => {
  return addressToArray(address).join(delimiter)
}

export const addressToArray = (address = {}) => {

  const { unitNumber = '', buildingName, houseNumber, street, district, city, postcode } = address

  const alwaysInclude = [
    district,
    city,
    postcode
  ]

  if (houseNumber && street && !unitNumber && !buildingName) {
    return [`${houseNumber} ${street}`, ...alwaysInclude].filter(Boolean)
  }

  const joinedStreetName = [buildingName, houseNumber, street].filter(Boolean).join(', ')

  return [
    unitNumber,
    joinedStreetName,
    ...alwaysInclude
  ].filter(Boolean)
}

export default addressToString
