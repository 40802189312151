import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import addClass from '../../shared/addClass'
import removeClass from '../../shared/removeClass'
import { BODY_CLASS_ARGS } from '../constants'

export default class CleanModal extends React.Component {

  static propTypes = {
    buttons: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.any, // Anything parsable by `classnames`
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.node,
    withCloseIcon: PropTypes.bool,
  }

  static defaultProps = {
    isOpen: false,
    withCloseIcon: true,
  }

  componentDidMount () {
    if (this.props.isOpen) addClass(...BODY_CLASS_ARGS)
  }

  componentDidUpdate (oldProps) {
    if (oldProps.isOpen !== this.props.isOpen) {
      const fn = this.props.isOpen ? addClass : removeClass
      fn(...BODY_CLASS_ARGS)
    }
  }

  componentWillUnmount () {
    removeClass(...BODY_CLASS_ARGS)
  }

  close = e => {
    const { onClose } = this.props
    e && e.preventDefault()
    onClose && onClose()
  }

  renderButtons () {
    const { buttons } = this.props
    if (!buttons) return null
    return (
      <div className="modal-footer modal-footer-dark">
        {buttons}
      </div>
    )
  }

  render () {
    const { isOpen, className, title, children, withCloseIcon } = this.props
    return (
      <div>
        <div className={classnames('modal modal-centered fade', { 'in': isOpen, 'show': isOpen })} onClick={this.close}>
          <div className={classnames('modal-dialog', className)} onClick={(evt) => evt.stopPropagation()}>
            <div className="modal-content box">
              <div className="modal-header modal-header-no-border">
                { withCloseIcon && <a
                  href="#"
                  type="button"
                  className="close"
                  onClick={this.close}
                >
                  <i className="modal-header-icon icon icon-close" />
                </a>}
                {Boolean(title) && <h2 className="modal-title modal-title-clean">{title}</h2>}
              </div>
              <div className="modal-body">
                {children}
              </div>
            {this.renderButtons()}
            </div>
          </div>
        </div>
        {isOpen && <div className={'modal-backdrop fade in'} />}
      </div>
    )
  }
}
