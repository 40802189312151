import React from 'react'
import classNames from 'classnames'

export default class ErpRating extends React.Component {

  static defaultProps = {
    label: 'N/A'
  }

  render () {
    const { ratingClass, size } = this.props
    const classes = classNames('erp-tag', {
      ['erp-tag-' + ratingClass]: ratingClass,
      ['erp-tag-' + size]: size
    })
    return (
      <div className={classes}>
        {this.props.label}
      </div>
    )
  }
}
